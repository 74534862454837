import React, { useEffect, useState } from 'react'

export default function Type({pass_FiltercheckBox}) {
    const [filterTypeData, setfilterBrandData] = useState([])
    const typeApi = async () =>{
        const typeRes = await fetch("https://foyforyou.com/foyapi/api/Category/GetCategoryByTypeId?typeId=1")
        const typeResData = await typeRes.json();
        // console.log(typeResData,"typeResData>>>>>")
        setfilterBrandData(typeResData.data)
    }
    useEffect(()=>{
        typeApi()
    },[])
  return (
    <div>
    <div className='filter_item font-Harmonia'>
    {
        filterTypeData.map((type, key) => {
            return (
              <div className="filterTitle" key={key}>
                <label className="label_Brand">
                  <input
                    type="checkbox"
                    id="brand"
                    name="categoryIds"
                    value={type?.id}
                    onChange={(e) => {
                      pass_FiltercheckBox(e);
                    }}
                  />
                  {type?.name}
                  <div className="common-checkboxIndicator"></div>
                </label>
              </div>
            )
          })
        }
    </div>
</div>
  )
}
