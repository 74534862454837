import React, { createContext, useEffect, useState } from "react";
import clevertap from "clevertap-web-sdk";
import axios from "axios";

export const AppContext = createContext();

const ContextProvider = ({ children }) => {
  const userId = localStorage.getItem("userID");
  const [wishlistDataSet, setWishlistDataSet] = useState([]);
  const [counts, setCounts] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tapEventProduct, setTapEventProduct] = useState(null);
  const [tapEventUser, setTapEventUser] = useState(null);

  //CLEVER TAP INTEGRATION
  useEffect(() => {
    clevertap.privacy.push({ optOut: false });
    clevertap.privacy.push({ useIP: false });
    clevertap.init("6K7-WR6-9W7Z");
    const searchParams = new URLSearchParams(window.location.search);
    const productId = searchParams.get("product");
    setEventProduct(productId);
  }, []);

  const setEventProduct = async (productId) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("authorization", "your_auth_token_here");

      var raw = JSON.stringify({
        product_id: productId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        "https://foyforyou.com/foyapi/api/customer/Product/GetProductByParameters",
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      let product = result.data[0];
      setTapEventProduct(product);
      // setEventUser();
      cleverTapEvent(product, "VTO_Opened_(Prod_Tried_on_VTO)");
    } catch (error) {
      console.log("Error:", error);
      setLoading(false);
    }
  };

  const cleverTapEvent = async (product, eventName) => {
    const userId = localStorage.getItem("userID");
    let tapEventUser = null;
    console.log("userIduserIduserIduserId", userId);
    
    if (userId) {

      const res = await axios.post(
        `https://foyforyou.com/foyapi/api/User/GetUserById?id=${userId}`
      );
      tapEventUser = res.data.data;
      setTapEventUser(tapEventUser);
    }

    let mainProduct;
    let userPayload = {};
    if (product) mainProduct = product;
    else mainProduct = tapEventProduct;

    if (tapEventUser) {
      userPayload = {
        "User Name": `${tapEventUser.first_name} ${tapEventUser.last_name}`,
        "User Age": tapEventUser.age,
        "User Gender": tapEventUser.gender,
        "User Phone Number": tapEventUser.phone_number,
        "User Email": tapEventUser.email,
        "User Country": "",
        "User City": "",
      };
    } else {
      userPayload = {
        "User id": localStorage.getItem("guestId"),
      };
    }

    const payload = {
      ...userPayload,
      "Product ID": mainProduct?.id,
      "Product Name": mainProduct?.name,
      "Product URL": window.location.href,
      "Product Shade/Color": mainProduct?.category.color,
      "Product Size": mainProduct?.size,
      "Product Cat": mainProduct?.category.name,
      "Product List_Price": mainProduct?.list_price,
      "Product Your_price": mainProduct?.your_price,
    };

    clevertap.event.push(eventName, payload);
  };

  // ------------------------CLEVER-TAP-INTEGRATION-END-----------------
  const [redirect, setRedirect] = useState("");
  const [variantId, setvariantId] = useState([]);

  const WishlistApi = async () => {
    const userId = localStorage.getItem("userID");
    if (userId !== null) {
      try {
        const WishlistRes = await fetch(
          `https://foyforyou.com/foyapi/api/customer/ManageCart/GetWishListItemByUser?userId=${userId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const WishlistData = await WishlistRes.json();
        if (WishlistData?.statusCode === 200) {
          setWishlistDataSet(WishlistData.data);
          setCounts(WishlistData.data.length);
          setLoading(false);
        } else {
          console.error("Failed to fetch wishlist data", WishlistData);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching wishlist data", error);
        setLoading(false);
      }
    }
    //
  };

  console.warn("redirect", redirect, variantId);

  return (
    <AppContext.Provider
      // value={{ wishlistDataSet, counts, WishlistApi, loading, setLoading, cleverTapEvent }}
      value={{
        wishlistDataSet,
        counts,
        WishlistApi,
        loading,
        setLoading,
        redirect,
        setRedirect,
        variantId,
        cleverTapEvent,
        setvariantId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
