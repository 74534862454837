import React, { useEffect, useState } from "react";

export default function Brands({
  pass_FiltercheckBox,
  selectedProduct,
  brandValues,
}) {
  const [filterBrandData, setfilterBrandData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const filterBrandData = [
  //   {
  //     id: 10046,
  //     name: "Shibel ",
  //   },
  //   {
  //     id: 10045,
  //     name: "Revolution Relove ",
  //   },
  //   {
  //     id: 10041,
  //     name: "Disguise Cosmetics ",
  //   },
  //   {
  //     id: 10040,
  //     name: "Color FX ",
  //   },
  //   {
  //     id: 10039,
  //     name: "Unintrptd ",
  //   },
  //   {
  //     id: 10038,
  //     name: "Diam Beauty ",
  //   },
  //   {
  //     id: 17,
  //     name: "Type Beauty Inc ",
  //   },
  //   {
  //     id: 13,
  //     name: "Just Herbs ",
  //   },
  //   {
  //     id: 11,
  //     name: "Holika Holika ",
  //   },
  // ];
  const brandApi = async () => {
    setLoading(true);
    const payloadBrand = {
      categoryIds: "5",
      sub_category_id: "12,13,14",
      skip: 0,
      take: 1000,
      leaf_level_category_Ids:
        "10106,10105,10040,10099,10101,10071,14,10023,10096,10020,12,10028",
    };
    const brandRes = await fetch(
      "https://foyforyou.com/foyapi/api/customer/Product/fetchProductByParams",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payloadBrand),
      }
    );
    // const payloadBrand = {
    //   skip: 0,
    //   take: 100,
    //   isAllowInactive: false,
    // };
    // const brandRes = await fetch(
    //   "https://foyforyou.com/foyapi/api/Brand/GetAll",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(payloadBrand),
    //   }
    // );
    // console.log(brandRes,"brandResbrandRes>>>>>>>>>")
    const brandResData = await brandRes.json();
    setLoading(false);
    // console.log(brandResData, "brandResData>>>>");
    setfilterBrandData(brandResData.data);
  };
  // console.log("filterBrandData", filterBrandData);
  useEffect(() => {
    brandApi();
  }, []);
  const brand_names = [
    "Holika Holika",
    "Re'equil",
    "Diam Beauty",
    "Color FX",
    "Just Herbs",
    "Disguise Cosmetics",
    "L.A. Girl",
    "Unintrptd",
    "Lamel",
    "Revolution Relove",
    "Shibel",
    "Thank You Farmer",
    "The Face Shop",
    "Papa Recipe",
    "Makeup Revolution ",
    "Type Beauty Inc",
    "Raw Beauty",
    "mCaffeine",
    "Missha",
    "Pigment play",
    "Iris Cosmetics",
    "MARS",
    "ROM&ND",
    "Dear Klairs",
    "Trudiance ",
    "Aravi Organic",
    "IDUN MINERALS",
  ];
  var setBrands = [];
  // const uniqueBrands = filterBrandData.filter(
  //   (brand, index, self) =>
  //     index === self.findIndex((b) => b.brand_name === brand.brand_name)
  // );
  filterBrandData.forEach((product) => {
    if (product.extras[0]?.color_list.some(
      (color) => color.color && color.color !== "Multi" && color.stock !== 0
    )) {
      setBrands.push({
        brand_name: product.brand_name,
        brand_id: product.brand_id,
      });
    }
  });
  const uniqueBrands = Array.from(
    new Map(setBrands.map((brand) => [brand.brand_name, brand])).values()
  );
  console.log("uniqueBrands>>",uniqueBrands);

var brandValuesNew = brandValues?.split(',');
  

  console.log("selectedProduct", selectedProduct);
  

  return (
    <div>
      {loading === true ? (
        <>
          <div className="loaderContainer">
            <img className="loader" src="/loader.svg" alt="loader" />
          </div>
        </>
      ) : (
        <div
          className="filter_item font-Harmonia"
          style={{ paddingBottom: "10px" }}
        >
          {uniqueBrands &&
            uniqueBrands.map((brand, key) => {
              // console.log("brand?.brand?.name", brand.brand_name);
              return (
                // brand_names.includes(brand?.name) && (

                <div className="filterTitle" key={key}>
                  <label className="label_Brand">
                    <input
                      type="checkbox"
                      // id="brand"
                      // name="brand"
                      id={`brand-${brand?.brand_id}`}
                      name="brand"
                      value={brand?.brand_id}
                      inputkey={brand?.brand_name}
                      checked={
                       brandValuesNew && brandValuesNew.length > 0
      ? brandValuesNew.some((value) => String(value) === String(brand?.brand_id))
      : false

                      }
                      onChange={(e) => {
                        pass_FiltercheckBox(e);
                      }}
                    />
                    <span>{brand?.brand_name}</span>
                    <div className="common-checkboxIndicator"></div>
                  </label>
                </div>
                // )
              );
            })}
          <div style={{ height: "50px" }}></div>
        </div>
      )}
    </div>
  );
}
