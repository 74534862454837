import React, { useEffect, useState } from "react";

export default function Price({ pass_FiltercheckBox, priceValues }) {
  const [filterpriceData, setFilterPriceData] = useState([]);
  const [prices, setPrices] = useState([]);
  
  // Load selected prices from localStorage or default to provided priceValues
  const initialSelectedPrices = JSON.parse(localStorage.getItem("selectedPrices")) || priceValues?.split(",") || [];
  const [selectedPrices, setSelectedPrices] = useState(initialSelectedPrices);

  // Fetch the price list
  const fetchPriceList = async () => {
    try {
      const payloadPrice = {
        relevence: true,
        pricerange: "1",
      };
      const response = await fetch(
        "https://foyforyou.com/foyapi/api/customer/Customer/priceList",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payloadPrice),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch price list");
      }

      const data = await response.json();
      setFilterPriceData(data.data || []);
    } catch (error) {
      console.error("Error fetching price list:", error);
    }
  };

  // Fetch product data based on the price range
  const fetchProductData = async (priceRange) => {
    try {
      const payloadBrand = {
        categoryIds: "5",
        sub_category_id: "12,13,14",
        pricerange: priceRange,
        skip: 0,
        take: 1000,
      };

      const response = await fetch(
        "https://foyforyou.com/foyapi/api/customer/Product/fetchProductByParams",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payloadBrand),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch products for price range ${priceRange}`);
      }

      const data = await response.json();
      return data.data || [];
    } catch (error) {
      console.error(`Error fetching product data for ${priceRange}:`, error);
      return [];
    }
  };

  // Filter and set valid prices
  const fetchPrices = async () => {
    try {
      const allPrices = await Promise.all(
        filterpriceData.map(async (priceRange) => {
          const products = await fetchProductData(priceRange);
          const validProducts = products.filter((product) =>
            product.extras[0]?.color_list.some(
              (color) =>
                color.color && color.color !== "Multi" && color.stock !== 0
            )
          );

          if (validProducts.length > 0) {
            return priceRange;
          }
          return null;
        })
      );

      const uniquePrices = [...new Set(allPrices.filter((price) => price !== null))];
      setPrices(uniquePrices);
    } catch (error) {
      console.error("Error fetching prices:", error);
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    setSelectedPrices((prev) => {
      const updatedPrices = checked
        ? [...prev, value]
        : prev.filter((price) => price !== value);

      // Save to localStorage
      localStorage.setItem("selectedPrices", JSON.stringify(updatedPrices));
      return updatedPrices;
    });

    pass_FiltercheckBox(e);
  };

  // Fetch price list on component mount
  useEffect(() => {
    fetchPriceList();
  }, []);

  // Fetch prices after price list is fetched
  useEffect(() => {
    if (filterpriceData.length > 0) {
      fetchPrices();
    }
  }, [filterpriceData]);

  return (
    <div>
      <div className="filter_item font-Harmonia">
        {prices.map((priceRange, key) => (
          <div className="filterTitle" key={key}>
            <label className="label_Brand">
              <input
                type="checkbox"
                id="brand"
                name="pricerange"
                value={priceRange}
                checked={selectedPrices.includes(priceRange)} // Check from state
                onChange={handleCheckboxChange}
              />
              {priceRange}
              <div className="common-checkboxIndicator"></div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
