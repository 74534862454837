import React from "react";
import FaceFilters from "../filters/Face/index.json";

const Face = ({ subcategoryFilter }) => {
  const selectedVal = localStorage.getItem("selectedVal")
  return (
    <div>
      {/* <div className="toneLips d-flex"> */}
      <div
        className=" "
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        {FaceFilters["faces"].length !== 0
          ? FaceFilters["faces"].map((filter, key) => {
              return (
                <div className="font-RedHat " key={key}>
                  <label className="filter_name">
                    <input
                      type="radio"
                      value={filter.value}
                      name={filter.name}
                      checked={selectedVal === filter.value}
                      onChange={(e) => {
                        subcategoryFilter(e);
                      }}
                    />
                    {filter.filter_name}
                    <div className="boxButton"></div>
                  </label>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default Face;
