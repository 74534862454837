import React, { useEffect, useState } from 'react'

export default function Skintype({pass_FiltercheckBox}) {
    const [filterSkinData, setfilterSkinData] = useState([])
    const skinApi = async () =>{
        const skinRes = await fetch("https://foyforyou.com/foyapi/api/Category/Get_SubCategory_SkinType_Concern_ByCategoryId")
        const skinResData = await skinRes.json();
        // console.log(skinResData,"skinResData>>>>>")
        setfilterSkinData(skinResData.data.skinTypeList)
    }
    useEffect(()=>{
        skinApi()
    },[])
    return (
        <div>
        <div className='filter_item font-Harmonia'>
            {
                filterSkinData.map((skin, key) => {
                    return (
                    <div className="filterTitle" key={key}>
                        <label className="label_Brand">
                        <input
                            type="checkbox"
                            id="brand"
                            name="skin_type"
                            value={skin?.id}
                            onChange={(e) => {
                            pass_FiltercheckBox(e);
                            }}
                        />
                        {skin?.name}
                        <div className="common-checkboxIndicator"></div>
                        </label>
                    </div>
                    )
                })
                }
        </div>
        </div>
    )
}