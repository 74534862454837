import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Product from "../pages/product";
import Notfound from "../pages/notfound";
import Ourstory from "../pages/Ourstory";
import Careers from "../pages/Careers";
import Team from "../pages/Team";

const RoutesPage = ({isOverlay}) => {
  const Routemap = [
    {
      path: "/",
      element: <Product isOverlay={isOverlay} />,
    },
    {
      path: "/ourstory",
      element: <Ourstory />,
    },
    {
      path: "/careers",
      element: <Careers />,
    },
    {
      path: "/terms",
      element: <Team />,
    },
    {
      path: "*",
      element: <Notfound />,
    },
  ];
  return (
    <BrowserRouter>
      <Routes>
        {Routemap?.map((item, key) => {
          return <Route path={item.path} element={item.element} key={key} />;
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesPage;