import React from "react";
import Productrecommendations from "./Productrecommendations";

const SimilarProducts = ({
  data,
  //   HandleClose,
  cleverTapEvent,
  handleAddToCart,
  wishlistDataSet,
  showSimilarProducts,
  setShowSimilarProducts,
  handlers,
  upHandler,
  fetchData,
}) => {
  return (
    <>
      {showSimilarProducts && (
        <button
          className="crossbtn"
          id="crossBtn"
          onClick={() => setShowSimilarProducts(false)}
        >
          <svg
            className={`Icon Icon--close`}
            role="presentation"
            viewBox="0 0 16 14"
          >
            <path
              d="M15 0L1 14m14 0L1 0"
              stroke="currentColor"
              fill="none"
              fillRule="evenodd"
            ></path>
          </svg>
        </button>
      )}
      <div
        // {...upHandler}
        id=""
        className=""
        style={{ height: "100%" }}
        // className={`abc ${
        //   showSimilarProducts ? "similarInner productwrapper" : "XsimilarInner"
        // }`}
      >
        {/* <div>sadasdfsadfsadf</div> */}
        <Productrecommendations
          handlers={handlers}
          upHandler={upHandler}
          showSimilarProducts={showSimilarProducts}
          setShowSimilarProducts={setShowSimilarProducts}
          cleverTapEvent={cleverTapEvent}
          handleAddToCart={handleAddToCart}
          wishlistDataSet={wishlistDataSet}
          fetchData={fetchData}
        />
      </div>
    </>

    // </div>
  );
};

export default SimilarProducts;
