import React from 'react';
import '../App.css'
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import Footer from './footer.js'


const DesktopCompt = () => {
    let link = window.location.href;
    // console.log("link", link);
    return (
      <>
        <div className="desktopview">
          <div className="smallNav">
            <ul className="navItems">
              <li className="navlinks">
                <a href="https://foyforyou.com/shop-all/Skin%20?c=1">Skin</a>
              </li>
              <li className="navlinks navlink_makeup">
                <a href="https://foyforyou.com/shop-all/Makeup?c=5">MAKEUP</a>
              </li>
              {/* <li className='navlinks'>
                        BATH & bODY
                    </li> */}
              <li className="navlinks">
                <a href="https://foyforyou.com/shop-all/Hair?c=10005">HAIR</a>
              </li>
              {/* <li className='navlinks'>
                        mEN
                    </li>
                    <li className='navlinks'>
                        for moms
                    </li>
                    <li className='navlinks'>
                        WELLNESS
                    </li>
                    <li className='navlinks'>
                        WHAT’S NEW
                    </li> */}
            </ul>
          </div>
          <div className="desktop_view">
            <h2>Scan, Play, Slay!</h2>
            <p>
              Scan the QR code to launch your virtual makeup playground, and let
              the fun begin! Experience the future of makeup seamlessly from
              your mobile phone.
            </p>
            <div className="mid_QRCode">
              <div className="qrcodeImg">
                <QRCode value={link} />
              </div>
              <img src="./vto.png" className="vtoImg" />
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </>
    );
}

export default DesktopCompt