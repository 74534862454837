import React, { useEffect, useState } from "react";

export default function Leaf({
  pass_FiltercheckBox,
  filterLeafData,
  selectedProduct,
  leaflevelValues,
}) {
  // const [filterLeafData, setfilterLeafData] = useState([])
  // const LeafApi = async () => {
  //     const LeafRes = await fetch("https://foyforyou.com/foyapi/api/leaf_level_category/GetAllLeafCategory", {
  //         method: "POST",
  //         headers: {
  //             "Content-Type": "application/json",
  //         },
  //     });
  //     // console.log(LeafRes, "LeafRes>>>>>>>>>")
  //     const LeafResData = await LeafRes.json();
  //      console.log(LeafResData, "LeafRes>>>>")
  //     setfilterLeafData(LeafResData.data)
  // }
  // console.log(filterLeafData, "filterLeafData");
  const filterBy = [
    "Lipstick",
    "Liquid Lipstick",
    "Lip Gloss",
    "Lip Tint",
    "Lip Crayon",
    "Lip Liner",
    "Lip Plumper",
    "Compact",
    "Kajal",
    "Mascara",
    "Eye Brow Enhancers",
    "Eyeliner",
    "Eyeshadow",
    "Blush",
    "Foundation",
    "Concealer",
    "Bronzer",
    "Contour",
    "Highlighter",
  ];

  const [filteredleafData, setfilterLeafData] = useState([]);
  const [loading, setLoading] = useState(false);

  const leafApi = async () => {
    setLoading(true);
    const payloadBrand = {
      categoryIds: "5",
      sub_category_id: "12,13,14",
      skip: 0,
      take: 1000,
      leaf_level_category_Ids:
        "10106,10105,10040,10099,10101,10071,14,10023,10096,10020,12,10028",
    };
    const brandRes = await fetch(
      "https://foyforyou.com/foyapi/api/customer/Product/fetchProductByParams",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payloadBrand),
      }
    );
    // const payloadBrand = {
    //   skip: 0,
    //   take: 100,
    //   isAllowInactive: false,
    // };
    // const brandRes = await fetch(
    //   "https://foyforyou.com/foyapi/api/Brand/GetAll",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(payloadBrand),
    //   }
    // );
    // console.log(brandRes,"brandResbrandRes>>>>>>>>>")
    const brandResData = await brandRes.json();
    setLoading(false);
    // console.log(brandResData,"brandResData>>>>")
    setfilterLeafData(brandResData.data);
  };
  // console.log(filterBrandData,"filterBrandData>>>")
  // useEffect(() => {
  //   if (filterLeafData && filterLeafData.length > 0) {
  //     setfilterLeafData(filterLeafData);
  //   } else {
  //     leafApi();
  //   }
  // }, []);
  // console.log("filterLeafData", filterLeafData);

  // const uniqueLeafs = filteredleafData.filter(
  //   (brand, index, self) =>
  //     index ===
  //     self.findIndex(
  //       (b) => b.leafCategories[0].title === brand.leafCategories[0].title
  //     )
  // );
  // console.log("LEAF selectedProduct", selectedProduct);
  return (
    <div>
      {loading === true ? (
        <>
          <div className="loaderContainer">
            <img className="loader" src="/loader.svg" alt="loader" />
          </div>
        </>
      ) : (
        <div className="filter_item font-Harmonia">
          {filterLeafData.map((Leaf, key) => {
            // console.log("Leaf", Leaf);
            // console.log(
            //   "leaflevelValues",
            //   selectedProduct?.leafCategories.some(item =>item.id=== Leaf?.id),
            //   Leaf?.id
            // );
            return (
              filterBy.some((item) => item === Leaf?.title) && (
                <div className="filterTitle" key={key}>
                  <label className="label_Brand">
                    <input
                      type="checkbox"
                      id={`leaf-${Leaf?.id}`}
                      name="leaf_level_category_Ids"
                      value={Leaf?.id}
                      checked={
                        leaflevelValues
                          ? leaflevelValues.includes(String(Leaf?.id))
                          : false
                      }
                      onChange={(e) => {
                        pass_FiltercheckBox(e);
                      }}
                    />
                    <span>{Leaf?.title}</span>
                    <div className="common-checkboxIndicator"></div>
                  </label>
                </div>
              )
            );
          })}
        </div>
      )}
    </div>
  );
}
