import React, { useEffect, useState } from 'react'

export default function Categories({
  pass_FiltercheckBox,
  categoryData,
  selectedProduct,
  leafvalue,
}) {
  const [filtercategoriesData, setfiltercategoriesData] =
    useState(categoryData);
  // const CategoryApi = async () =>{
  //     const CategoryRes = await fetch("https://foyforyou.com/foyapi/api/Category/Get_SubCategory_SkinType_Concern_ByCategoryId")
  //     const CategoryResData = await CategoryRes.json();
  //     // console.log(CategoryResData,"CategoryResData>>>>>")
  //     setfiltercategoriesData(CategoryResData.data.subCategoryList)
  // }
  // useEffect(()=>{
  //     CategoryApi()
  // },[])

  const filterBy = ["Lips", "Eye", "Face"];
  console.log("categoryData", categoryData);
  return (
    <div>
      <div className="filter_item font-Harmonia">
        {categoryData.map((category, key) => {
          const filter = filterBy.some(
            (filterItem) => filterItem === category?.name
          ); // Corrected use of some()

          return (
            filter && (
              <div className="filterTitle" key={key}>
                <label className="label_Brand yoooo">
                  <input
                    type="checkbox"
                    id={`sub_category_${category?.id}`} // Changed id to be unique for each checkbox
                    name="sub_category_id"
                    value={category?.id}
                    checked={
                      leafvalue
                        ? leafvalue?.includes(String(category?.id))
                        : false
                    } // Simplified checked condition
                    onChange={(e) => {
                      pass_FiltercheckBox(e);
                    }}
                  />
                  {category?.name}
                  <div className="common-checkboxIndicator"></div>
                </label>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}
