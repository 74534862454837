import React, { useState, useEffect, useCallback, useRef } from "react";
import "../styles/loginpopup.css";
import Color from "./Color";
import Spinner from "./spinner";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LoginPopup = ({
  PopTrue,
  onclose,
  handleAddToCart,
  WishlistApi,
  action,
  handleButNow,
  redirect,
  setRedirect,
  variantId,
}) => {
  const [Mobnumber, setMobnumber] = useState();
  const [otp, setotp] = useState([]);
  const [userDetails, setuserDetails] = useState([]);
  const [loginpopup, setloginpopup] = useState(true);
  const [otpPopup, setOtppopup] = useState(false);
  const [newaccount, setnewaccount] = useState(false);
  const [thankYouPopUp, setThankYouPopUp] = useState(false);
  const [Otpmsg, setOtpmsg] = useState(false);
  const [error, setError] = useState(" ");
  const [verifyotp, setVerifyotp] = useState(false);
  const [formMsg, setFormMsg] = useState([]);
  const [closePop, setClosePop] = useState(PopTrue);
  const [loading, setLoading] = useState(false);
  const [optError, setOtpError] = useState("");

  //   const [verifyingOtp, setVerifyotp] = useState(false);
  console.log("onclose>>>", onclose);

  //   const handleChange = (e) => {
  //     const value = e.target.value
  //       .replace(/[^0-9]/g, "") // Allow only numeric characters
  //       .slice(0, 10); // Limit length to 10 characters

  //     setMobnumber(value);
  //     handleMouseOut(value);
  //   };

  //   const handleBlur = (e) => {
  //     handleMouseOut(e.target.value);
  //   };

  //   const handleMouseOut = (value) => {
  //     // Define your handleMouseOut logic here
  //     console.log("Phone number processed:", value);
  //   };

  const handleInputChange = (value) => {
    // Filter out non-digit characters
    const numericValue = value.replace(/\D/g, "");

    // Check if the length is more than 10 characters
    if (numericValue.length > 10) {
      return;
    }

    // Set the mobile number in state
    setMobnumber(numericValue);

    // Check if the mobile number is provided
    if (!numericValue) {
      setError("Mobile Number is required");
    } else {
      // Regex to check if the mobile number contains only digits
      const Mobregex = /^\d+$/;

      // Check if the phone number is only digits
      if (!Mobregex.test(numericValue)) {
        setError("Mobile Number should contain only digits");
      } else if (numericValue.length > 10) {
        setError("Mobile Number cannot exceed 10 digits");
      } else {
        setError(""); // No error
      }
    }
  };

  const loginOtp = async () => {
    if (Mobnumber) {
      setLoading(true);
      const mobOtpRes = await fetch(
        `https://foyforyou.com/foyapi/api/Account/RequestOTP?phoneNumber=${Mobnumber}`
      );
      const mobOtpData = await mobOtpRes.json();
      if (mobOtpData.statusCode === 200) {
        console.log(mobOtpData, "mobOtpData>>>");
        setOtppopup(true);
        setloginpopup(false);
        setLoading(false);
        startTimer();
      }
    }
  };

  const guestId = localStorage.getItem("guestId");

  const TransferUserById = (userId) => {
    let data = JSON.stringify({
      guest_Id: guestId,
      user_id: userId,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://foyforyou.com/foyapi/api/customer/ManageCart/TransferCartGuestToUser",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verifyOTP = async () => {
    setOtpError("");

    setLoading(true);
    // setVerifyotp(true);
    if (otp) {
      const OtpRes = await fetch(
        `https://foyforyou.com/foyapi/api/Account/LoginWithOTP?phoneNumber=${Mobnumber}&otp=${otp}`
      );
      const OtpData = await OtpRes.json();
      console.log(OtpData, "mobOtpData>>>");
      if (OtpData.statusCode == 200) {
        localStorage.setItem("userID", OtpData.data?.id);
        setOtpError("");
        setotp("");
        TransferUserById(OtpData.data?.id);
        setuserDetails({
          id: OtpData.data?.id,
          gender: OtpData.data?.gender,
          firstname: OtpData.data?.first_name,
          lastname: OtpData.data?.last_name,
          email_id: OtpData.data?.email,
        });
        setOtppopup(false);
        setloginpopup(false);
        setLoading(false);
        setThankYouPopUp(true);

        // if (OtpData.data.email !== null) {
        //   onclose();
        //   setClosePop(false);

        //   if (action === "addtocart") {
        //     handleAddToCart("", false);
        //   } else if (action === "wishlist") {
        //     handleAddToCart("", true);
        //     WishlistApi();
        //   } else if (action === "buyNow") {
        //     handleButNow();
        //   }
        // } else if (OtpData.data.email === null) {
        //   console.log("new account");
        // //   setnewaccount(true);
        // setThankYouPopUp(true)
        // }
        console.log("onclose>>>3", "redirect", redirect, variantId);
        
        setTimeout(() => {
          const userrId = localStorage.getItem("userID");
          if (redirect === "cart") {
            window.location.href = `https://foyforyou.com/checkout-new-mob/cart?UserId=${userrId}`;
          } else if (redirect === "wishlist") {
            window.location.href = `https://foyforyou.com/product-list/wishlist`;
          } else if (redirect === "addwishlist") {
            handleAddToCart(null, true, variantId);
            WishlistApi();
          } else if (redirect === "buynow") {
            window.location.href = `https://foyforyou.com/checkout-new/buynow/${variantId}?UserId=${userrId}`;
          }
        }, 1000);
      } else {
        setOtpmsg(true);
        setotp("");
        setOtpError("Invalid OTP");
        setLoading(false);
        setTimeout(() => {
          setOtpmsg(false);
        }, 1e3);
      }
    }
  };

  const updateuser = async () => {
    if (userDetails) {
      setLoading(true);
      console.log("userDetails>>>>", userDetails);
      const apiUrl = "https://foyforyou.com/foyapi/api/User/Update";

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers as needed
        },
        body: JSON.stringify({
          id: userDetails.id,
          first_name: userDetails.firstname,
          last_name: userDetails.lastname,
          email: userDetails.email,
          gender: userDetails.gender,
          phone_number: Mobnumber,
          role_permissions_id: "user",
          // Add other payload data as needed
        }),
      };
      const userRes = await fetch(apiUrl, requestOptions);
      const userdata = await userRes.json();
      console.log(userdata, "userdata>>>");
      setLoading(false);
      onclose();
      setClosePop(false);
      if (action === "addtocart") {
        handleAddToCart("", false);
        WishlistApi();
      } else if (action === "wishlist") {
        handleAddToCart("", true);
        WishlistApi();
      } else if (action === "buyNow") {
        handleButNow();
      }
    }
  };

  //   const otpValidation = (otpVal) => {
  //     if (!otpVal) {
  //       setError("OTP is required");
  //       setotp(otpVal);
  //       setVerifyotp(true);
  //     } else {
  //       if (otpVal.length > 4) {
  //         setotp(otpVal.slice(0, 4));
  //         setError("");
  //       } else {
  //         if (otpVal.length < 4) {
  //           setotp(otpVal);
  //           setError("Otp is invalid");
  //           setVerifyotp(true);
  //         } else {
  //           setotp(otpVal);
  //           setError("");
  //         }
  //       }
  //     }
  //   };

  const otpValidation = (otpVal) => {
    setOtpError("");

    // Check if OTP is provided
    if (!otpVal) {
      setError("OTP is required");
      setotp("");
      setVerifyotp(false); // Assuming verification should not be triggered if OTP is not provided
      return;
    }

    // Check if OTP is numeric and has a maximum length of 4
    const otpNumber = /^\d{1,4}$/; // Regex to ensure OTP is numeric and up to 4 digits
    if (!otpNumber.test(otpVal)) {
      setError("OTP is invalid.");
      setotp("");
      setVerifyotp(false);
      return;
    }

    // If OTP length is valid and numeric
    if (otpVal.length === 4) {
      setotp(otpVal);
      setError("");
      setVerifyotp(true); // Assuming verification is triggered when OTP is valid
    } else {
      setotp(otpVal);
      setError("OTP is invalid.");
      setVerifyotp(false);
    }
  };

  const checkFName = (inputvalue) => {
    console.log("inputvalue>>>", inputvalue);
    if (!inputvalue || inputvalue.trim() === "") {
      setFormMsg({ ...formMsg, fName: "First name is required" });
    } else {
      setFormMsg({ ...formMsg, fName: " " });
    }
  };
  const checkLName = (lname) => {
    if (!lname || lname.trim() === "") {
      setFormMsg({ ...formMsg, lname: "Last name is required" });
    } else {
      setFormMsg({ ...formMsg, lname: " " });
    }
  };
  const checkEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || email.trim() === "") {
      setFormMsg({ ...formMsg, email: "Email is required" });
    } else {
      if (!emailRegex.test(email)) {
        setFormMsg({ ...formMsg, email: "Email is invalid" });
      } else {
        setFormMsg({ ...formMsg, email: "" });
      }
    }
  };
  const checkgender = (gender) => {
    if (!gender || gender.trim() === "") {
      setFormMsg({ ...formMsg, gender: "Gender is required" });
    } else {
      if (gender === "Gender") {
        setFormMsg({ ...formMsg, gender: "Gender is required" });
      } else {
        setFormMsg({ ...formMsg, gender: "" });
      }
    }
  };

  const maskNumber = (number) => {
    // Assuming Mobnumber is a string
    if (number.length <= 3) return number;
    const lastThreeDigits = number.slice(-3);
    const maskedPart = "*".repeat(number.length - 3);
    return maskedPart + lastThreeDigits;
  };
  //    const formattedNumber = `+91 ${maskNumber(Mobnumber)}`;

  const ChangePhoneNumberHandler = () => {
    // setMobnumber("");
    setotp("");
    setOtpError("");
    setError("");
    setOtppopup(false);
    setloginpopup(true);
  };

  const [timeLeft, setTimeLeft] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const intervalRef = useRef(null);
  const startTimer = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    setTimeLeft(30);
    setDisabled(true);

    intervalRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
          setDisabled(false);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  }, []);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleResendClick = () => {
    if (!disabled) {
      // Call your resend OTP function
      loginOtp();
      setotp("");
      // Restart the timer
      startTimer();
    }
  };
  const navigate = useNavigate();
  var userId = localStorage.getItem("userID");
  useEffect(() => {
    if (thankYouPopUp) {
      if (userId) {
        setTimeout((e) => {
          // window.location.href = `https://foyforyou.com/checkout-new-mob/cart?UserId=${userId}`;

          setClosePop(false);
        }, 3000);
      }
    }
  }, [thankYouPopUp]);

  return (
    <div>
      {loading && (
        <>
          {" "}
          <div className="loaderContainer">
            <img className="loader" src="/loader.svg" alt="loader" />
          </div>
        </>
      )}
      {closePop && (
        <div className="popuOverlay">
          {/* {Otpmsg && (
            <div className="otperrorMsg">
              <p>OTP does not matched</p>
              <button
                className="closeErrorMsg"
                onClick={() => {
                  setOtpmsg(false);
                }}
              >
                &times;
              </button>
            </div>
          )} */}

          <div className="popupContainer font-RedHat">
            <div className="popup-mid-Container">
              <img
                className="crosspopup"
                onClick={onclose}
                src="./cancel-icon.svg"
              />
              <div className="popup-innerContainer">
                <div className="popucontent">
                  <div className="foyImg">
                    <img src="./bg-logo.svg" />

                    {/* <div className='popcrossbtn' onClick={onclose}>&times;</div> */}
                  </div>
                  {thankYouPopUp ? (
                    // <>
                    //   <div className="accountPara">
                    //     <p className="boldPara">SIGNUP</p>
                    //     <p className="lightPara">Create your Account</p>
                    //     <input
                    //       className="acc_input"
                    //       type="text"
                    //       name="first_name"
                    //       value={userDetails.firstname}
                    //       placeholder="First name*"
                    //       onChange={(e) => {
                    //         console.log("e.target.value", e.target.value);
                    //         setuserDetails({
                    //           ...userDetails,
                    //           firstname: e.target.value,
                    //         });
                    //         checkFName(e.target.value);
                    //       }}
                    //       required
                    //     />
                    //     {formMsg.fName && (
                    //       <p className="errorMsg">{formMsg.fName}</p>
                    //     )}
                    //     <input
                    //       className="acc_input"
                    //       type="text"
                    //       value={userDetails.lastname}
                    //       name="last_name"
                    //       placeholder="Last name*"
                    //       onChange={(e) => {
                    //         setuserDetails({
                    //           ...userDetails,
                    //           lastname: e.target.value,
                    //         });
                    //         checkLName(e.target.value);
                    //       }}
                    //       required
                    //     />
                    //     {formMsg.lname && (
                    //       <p className="errorMsg">{formMsg.lname}</p>
                    //     )}
                    //     <input
                    //       className="acc_input"
                    //       type="email"
                    //       name="email id"
                    //       value={userDetails.email_id}
                    //       placeholder="Email Id*"
                    //       onChange={(e) => {
                    //         setuserDetails({
                    //           ...userDetails,
                    //           email_id: e.target.value,
                    //         });
                    //         checkEmail(e.target.value);
                    //       }}
                    //       required
                    //     />
                    //     {formMsg.email && (
                    //       <p className="errorMsg">{formMsg.email}</p>
                    //     )}
                    //     <select
                    //       className="acc_input"
                    //       name="gender"
                    //       id="gender"
                    //       placeholder="Gender*"
                    //       onChange={(e) => {
                    //         setuserDetails({
                    //           ...userDetails,
                    //           gender: e.target.value,
                    //         });
                    //         checkgender(e.target.value);
                    //       }}
                    //       required
                    //     >
                    //       <option value="Gender" id="genderInput">
                    //         Gender*
                    //       </option>
                    //       <option value="Male">Male</option>
                    //       <option value="Female">Female</option>
                    //     </select>
                    //     {formMsg.gender && (
                    //       <p className="errorMsg">{formMsg.gender}</p>
                    //     )}
                    //     <button
                    //       className="btn btn-dark"
                    //       onClick={() => {
                    //         updateuser();
                    //       }}
                    //     >
                    //       Submit
                    //     </button>
                    //   </div>
                    // </>
                    <div className="thankYou-container">
                      <p className="boldThankPara">Thank You For Logging In!</p>
                      <p className="">Happy Shopping!</p>
                    </div>
                  ) : (
                    <>
                      {otpPopup && (
                        <div className="otpPopup">
                          {optError !== "" ? (
                            <div className="optVerificationError ">
                              {optError}
                            </div>
                          ) : (
                            <p className="boldPara">Enter OTP</p>
                          )}
                          <div className="headparaContainer">
                            <p className="otplightPara">
                              {optError !== "" ? (
                                " Please re-enter correct OTP"
                              ) : (
                                <>
                                  an OTP has been sent to
                                  <span
                                    style={{ fontWeight: 800, color: "#000" }}
                                  >
                                    +91{"  "} {maskNumber(Mobnumber)}
                                  </span>
                                </>
                              )}
                            </p>
                            <div
                              className="changePhn"
                              onClick={ChangePhoneNumberHandler}
                            >
                              Change phone Number?
                            </div>
                          </div>
                          <div className="head_otp_container">
                            <div className="otp_container">
                              <input
                                //   id="EnterOtp"
                                type="text"
                                //   placeholder="Enter OTP"
                                maxLength={4}
                                value={otp}
                                onChange={(e) => {
                                  otpValidation(e.target.value);
                                }}
                                onBlur={(e) => {
                                  otpValidation(e.target.value);
                                }}
                              />
                            </div>
                            {error && <p className="errorMsg">{error}</p>}
                            <p className="otpPara">
                              <a
                                href="javascript:void(0)"
                                onClick={handleResendClick}
                                className={disabled ? "disabled" : ""}
                              >
                                {disabled
                                  ? `Resend in ${timeLeft}s`
                                  : "Resend Now"}
                              </a>
                            </p>
                          </div>
                          <button
                            className={`btn  ${
                              loading
                                ? "btn-slate"
                                : otp && otp.length === 4 && "btn-dark"
                            }  `}
                            disabled={verifyotp ? "" : "disabled"}
                            onClick={() => {
                              verifyOTP();
                            }}
                          >
                            {loading === true && <Spinner />}
                            Verify & Login
                          </button>
                        </div>
                      )}
                      {loginpopup && (
                        <div className="contentpara">
                          <p className="boldPara">Login / Signup</p>
                          <p className="lightPara">
                            Please enter your phone number
                          </p>
                          <div className="phon_numb">
                            {/* <span>+91</span> */}
                            {/* <span className="divider">|</span> */}
                            {/* <input
                                type="number"
                                name="phone"
                                placeholder="Mobile Number..."
                                id="phonenumber"
                                value={Mobnumber}
                                onChange={(e) => {
                                  handleMouseOut(e.target.value);
                                }}
                                onBlur={(e) => {
                                  handleMouseOut(e.target.value);
                                }}
                              /> */}
                            <input
                              type="text" // Use text type as 'number' doesn't support maxlength
                              value={Mobnumber}
                              onChange={(e) =>
                                handleInputChange(e.target.value)
                              }
                              onBlur={(e) => handleInputChange(e.target.value)}
                              className="phone-number"
                              maxLength="10"
                            />
                          </div>
                          {error && <p className="errorMsg">{error}</p>}

                          <div className="btn-container">
                            <button
                              className={`btn ${
                                loading
                                  ? "btn-slate"
                                  : Mobnumber &&
                                    Mobnumber.length === 10 &&
                                    "btn-dark"
                              }  `}
                              onClick={() => {
                                loginOtp();
                              }}
                            >
                              {loading === true && <Spinner />}
                              Get OTP
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPopup;
