import React, { useEffect, useState, useContext } from "react";
import Header from "../components/header";
// import Footer from "../components/footer";
import "../styles/product.css";
import Lips from "../components/Lips";
import Eyes from "../components/Eyes";
import Face from "../components/Face";
import Filter from "../components/Filter";
import axios from "axios";
import LoginPopup from "../components/LoginPopup";
import DesktopCompt from "../components/DesktopCompt";
import Productrecommendations from "../components/Productrecommendations";
import { AppContext } from "../context/appContext";
import SimilarProducts from "../components/similarProducts";
import { useSwipeable } from "react-swipeable";

const Product = ({ isOverlay }) => {
  const {
    loading,
    setLoading,
    counts,
    wishlistDataSet,
    WishlistApi,
    redirect,
    setRedirect,
    variantId,
    setvariantId,
    cleverTapEvent,
  } = useContext(AppContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [checkedValue, setCheckedValue] = useState("");


  window.ymkAsyncInit = function () {
    window.YMK.init({
      autoOpen: false,
      width: "375",
      height: windowHeight / 1.65,
    });

    window.YMK.addEventListener("opened", function () {});
    window.YMK.addEventListener("reset", function () {
      console.log("reset");
      setCheckedValue("");
    });
    window.YMK.addEventListener("compare", function () {});
    window.YMK.addEventListener("videoLoaded", function () {
      console.log("videoLoaded");
    });
    window.YMK.addEventListener("photoLoaded", function () {
      console.log("photoLoaded");
    });
    window.YMK.addEventListener("loaded", function () {
      console.log("loaded");
    });
    window.YMK.addEventListener("shadeFinderComplete", function (r) {
      console.log("shadeFinderComplete", r);
    });
  };

  window.ymkAsyncInit();

  // window.addEventListener("load", (event) => {
  //   window.ymkAsyncInit = function () {
  //     window.YMK.init({
  //       autoOpen: false,
  //       width: "375",
  //       height: "360",
  //     });
  //     window.YMK.addEventListener("opened", function () {});
  //     window.YMK.addEventListener("reset", function () {});
  //     window.YMK.addEventListener("compare", function () {});
  //     window.YMK.addEventListener("videoLoaded", function () {
  //       console.log("videoLoaded");
  //     });
  //     window.YMK.addEventListener("photoLoaded", function () {
  //       console.log("photoLoaded");
  //     });
  //     window.YMK.addEventListener("loaded", function () {
  //       console.log("loaded");
  //     });
  //     window.YMK.addEventListener("shadeFinderComplete", function (r) {
  //       console.log("shadeFinderComplete", r);
  //     });
  //   };

  //   window.ymkAsyncInit();
  // });

  
  const [activeCategory, setActiveCategory] = useState("LIPS");
  const [variantColors, setvariantColors] = useState([]);
  const [filter, setFilter] = useState(false);
  const [productID, setProductID] = useState(0);
  const [ymkTrue, setYMKTrue] = useState(null);
  const [product, setProduct] = useState();
  const newID = localStorage.getItem("userID");
  const [userId, setUserId] = useState(newID);
  const [poputrue, setPoputrue] = useState();
  const [filterProducts, setfilterProducts] = useState("");

  const [productShade, setproductShade] = useState();
  const [qty, setQty] = useState(1);
  const [atcPopup, setAtcPopup] = useState(false);
  const [leafValues, setLeafValues] = useState([]);
  const [staticFilter, setStaticFilter] = useState(false);
  // const [] = useState(false);
  const [atcTrue, setAtcTrue] = useState(false);
  const [wishlistTrue, setWishlistTrue] = useState(false);
  const [wishlistId, setWishlistId] = useState(null);
  const [wishlistfound, setWishlistfound] = useState(false);
  const [action, setAction] = useState("");
  const [popmsg, setPopmsg] = useState("");
  const [variantPrice, setVariantPrice] = useState(0);
  const [variantDiscount, setVariantDiscount] = useState(0);
  const [cartCounts, setCartCounts] = useState(0);
  const [staticFilterval, setStaticFilterval] = useState({
    take: 18,
    skip: 0,
    categoryIds: "5",
    sub_category_id: "14",
  });

  const productIdFromFilter = (data) => {
    setProductID(data);
  };

  const onclosePopup = () => {
    console.log("close");
    setPoputrue(false);
  };

  // guest ID
  const [guestId, setGuestId] = useState();

  const GuestID = async () => {
    console.log("12345678909876543234567890");
    if (!guestId && !userId) {
      const CartRes = await fetch(
        `https://foyforyou.com/foyapi/api/Account/GetGuestUserId`
      )
        .then((response) => response.json())
        .then((result) => {
          localStorage.setItem("guestId", result.data?.guestId);
          setGuestId(result.data?.guestId);
        })
        .catch((error) => console.error(error));
    }
  };

  useEffect(() => {
    const id = localStorage.getItem("guestId");
    setGuestId(id);
    if (!id && !userId) GuestID();
    cartDataApi();
  }, []);

  useEffect(() => {
    const updateWindowSize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateWindowSize);
    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  const subcategoryFilter = (data) => {
    setStaticFilterval({
      ...staticFilterval,
      leaf_level_category_Ids: data.target.value,
    });
    setLeafValues(data.target.value);
    localStorage.setItem("selectedVal", data.target.value);
    setStaticFilter(true);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const productId = searchParams.get("product");
    const userIdS = searchParams.get("user");
    var ymkTrues = searchParams.get("ymk");
    if (ymkTrues) {
      ymkTrues = ymkTrues.trim();
    }
    console.log("ymkTrues>>", typeof ymkTrues);
    setYMKTrue(ymkTrues);
  
    if (productId) {
      console.log("productset 1");
      setProductID(productId);
    } else {
      console.log("Product ID not found in the URL");
    }
    if (userIdS) {
      setUserId(userIdS);
      // console.log("User ID:", userIdS);
      // localStorage.setItem("userID", userIdS);
    } else {
      const userId2 = localStorage.getItem("userID");
      if (userId) {
        setUserId(userId2);
        localStorage.setItem("userID", userId2);
      } else {
        setUserId("");
      }
      console.log("User ID not found in the URL");
    }
  }, [productID, userId]);

  useEffect(() => {
    var widthYMK;
    var heightYMK;
    if (windowWidth < 768) {
      widthYMK = "375";
    } else {
      if (windowWidth == 280) {
        widthYMK = "290";
      } else {
        widthYMK = windowWidth;
      }
    }

    // Height adjustments for different mobile devices
    if (windowHeight < 600) {
      heightYMK = "200";
    } else if (windowHeight >= 600 && windowHeight < 700) {
      heightYMK = "250";
    } else if (windowHeight >= 700 && windowHeight < 800) {
      heightYMK = "300";
    } else if (windowHeight >= 800 && windowHeight < 900) {
      heightYMK = "350";
    } else {
      heightYMK = "400";
    }
    console.log("ymkTrue>>", ymkTrue);
    const searchParams = new URLSearchParams(window.location.search);
    var ymkTrues = searchParams.get("ymk");
    if (ymkTrues) {
      ymkTrues = ymkTrues.trim();
    }
    if (ymkTrues === "true") {
      console.log("trueYMK");
      // if(ymkTrue.includes('true')){

      window.YMK.open([true, true, true]);
      // }
    } else {
      // console.log("falseYMK");
      // if (ymkTrue.includes('true')) {
      //   console.log("falseYMK2");

      window.YMK.init({
        autoOpen: true,
        width: widthYMK,
        height: heightYMK,
      });

      // }
    }
  }, [windowWidth]);
  useEffect(() => {
    if (staticFilter == true) {
      findFilters(staticFilter, staticFilterval);
    } else {
    }
  }, [staticFilterval]);

  const HandleFilter = () => {
    console.log(product, "productFilter");
    cleverTapEvent(undefined, "VTO_Filter_Clicked");
    // alert();
    setStaticFilter(false);
    if (filter === true) {
      setFilter(false);
    } else {
      setFilter(true);
    }
    return filter;
  };

  const [cartData, setcartData] = useState([]);

  const cartDataApi = async () => {
    if (userId) {
      // const CartRes = await fetch(
      //   `https://foyforyou.com/foyapi/api/customer/ManageCart/GetCartItemByUser?userId=${userId}`
      // );

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        user_id: userId,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const CartRes = await fetch(
        `https://foyforyou.com/foyapi/api/customer/ManageCart/GetCartItemCountsByUserId`,
        requestOptions
      );
      const CartResData = await CartRes.json();
      if (CartResData.statusCode === 200) {
        setcartData(CartResData.data);
        setLoading(false);
        let cartCount = 0;
        // const cartDataLength = CartResData.data?.length;
        const cartDataLength =
          CartResData &&
          CartResData?.data.reduce((total, item) => total + item.qty, 0);

        // console.log("CartResData.data>>", cartDataLength);
        if (cartDataLength === 0) {
          setCartCounts(cartCount);
          // handleShopping();
        }
        setCartCounts(cartDataLength);
        // CartResData.data?.forEach((item) => {
        //   cartCount = cartCount + 1;
        //   setCartCounts(cartCount);
        // });

        // console.log("cartCount>>", cartCount);
      }
    } else {
      let data = JSON.stringify({
        guest_Id: guestId,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://foyforyou.com/foyapi/api/customer/ManageCart/GetCartItemCountsByUserId",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify("CartResDatadata", response.data));
          if (response.data.statusCode === 200) {
            setcartData(response.data.data);
            setLoading(false);
            let cartCount = 0;
            // const cartDataLength = response.data.data?.length;
            const cartDataLength = response.data?.data.reduce(
              (total, item) => total + item.qty,
              0
            );
            console.log("cartDataLength>>", cartDataLength);

            if (cartDataLength === 0) {
              setCartCounts(cartCount);
              // handleShopping();
            }
            // response.data.data?.forEach((item) => {
            //   cartCount = cartCount + 1;
            setCartCounts(cartDataLength);
            // });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleAddToCart = (event, wishlist, id) => {
    const User = localStorage.getItem("userID");
    if (User) {
      setLoading(true);
      var wishlistCon, qtynew, Wishid;
      if (wishlist === null) {
        if (action === "wishlist") {
          wishlist = true;
        } else {
          wishlist = false;
        }
      }
      if (wishlist === true) {
        if (wishlistfound === true) {
          wishlistCon = false;
          qtynew = 0;
          if (wishlistId === null) {
            Wishid = localStorage.getItem("wishlistId");
          } else {
            Wishid = wishlistId;
          }
        } else {
          wishlistCon = true;
          qtynew = qty;
        }
      } else {
        wishlistCon = false;
        qtynew = qty;
      }

      const isExistInWishlist = wishlistDataSet?.find(
        (item) => item.product_id === id
      );

      console.log("isExistInWishlist", isExistInWishlist);

      const payload = {
        user_id: User,
        product_id: id,
        iswishlistitem: wishlist,
        qty: isExistInWishlist?.product_id === id ? 0 : qtynew,
        ...(isExistInWishlist?.product_id === id && {
          id: isExistInWishlist?.id,
        }),
      };
      if (payload.qty == 1 && payload.iswishlistitem == false) {
        cleverTapEvent(undefined, "VTO_ATC_Clicked");
      } else {
        cleverTapEvent(undefined, "VTO_Add_to_Wishlist_Clicked");
      }

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        // url: "https://foyforyou.com/foyapi/api/customer/ManageCart/ManageCartByUser",
        url: "https://foyforyou.com/foyapi/api/customer/ManageCart/ManageCartByUserItem",
        headers: {
          "Content-Type": "application/json",
        },
        data: payload,
      };
      axios
        .request(config)
        .then((response) => {
          if (response.data.statusCode === 200) {
            setTimeout(() => {
              cartDataApi();
            }, 2000);
            WishlistApi();
            console.log("Carrt Ruiing ");
          }

          const dataWish = response.data.data;
          // dataWish &&
          //   dataWish.map((item) => {
          //     if (item.product_id === variantId) {
          //       localStorage.setItem("wishlistId", item.id);
          //       setWishlistId(item.id);
          //     }
          //   });

          if (wishlist === true) {
            setTimeout(() => {
              setAtcPopup(false);
            }, 1500);
            if (wishlistCon === false) {
              setWishlistTrue(false);
              setWishlistfound(false);
              setAtcPopup(true);
              setPopmsg("Item has been removed in wishlist");
              setAtcTrue(true);
              // wishlistfound = false;
              // localStorage.setItem('wishlistfound',false);
            } else {
              setWishlistTrue(true);
              setWishlistfound(true);
              setAtcPopup(true);
              setPopmsg("Item has been added in wishlist");
              setAtcTrue(true);
              // wishlistfound = true;
              // localStorage.setItem("wishlistfound",true)
            }
          } else {
            setAtcTrue(true);
            setAtcPopup(true);
            setPopmsg("Item has been added in cart");
            WishlistApi();
            setTimeout(() => {
              setAtcPopup(false);
            }, 1500);
          }
          WishlistApi();
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error: ", error);
          // setLoading(false);
        });
    } else {
      if (wishlist === true) {
        setPoputrue(true);
      } else {
        console.log("popopopopo", id, guestId);
        setLoading(true);

        const payload = {
          guest_Id: guestId,
          product_id: id,
          iswishlistitem: wishlist,
          qty: 1,
        };

        console.log("VARIENT", variantId);

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          // url: "https://foyforyou.com/foyapi/api/customer/ManageCart/ManageCartByUser",
          url: "https://foyforyou.com/foyapi/api/customer/ManageCart/ManageCartByUserItem",
          headers: {
            "Content-Type": "application/json",
          },
          data: payload,
        };
        axios.request(config).then((response) => {
          if (response.data.statusCode === 200) {
            setLoading(false);

            setTimeout(() => {
              cartDataApi();
              WishlistApi();
            }, 2000);
          }
        });
      }

      if (wishlist === true) {
        setAction("wishlist");
      } else {
        setAction("addtocart");
      }
    }
  };
  const handleButNow = (event) => {
    cleverTapEvent(undefined, "VTO_BN_Clicked");
    const user_id = localStorage.getItem("userID");
    console.log("userId>>>", user_id);
    setRedirect("buynow");
    console.log("variantId", variantId);
    if (user_id) {
      console.log("user_id", user_id, variantId);
      window.location.href = `https://foyforyou.com/checkout-new-mob/buynow/${variantId}?UserId=${user_id}`;
    } else {
      setPoputrue(true);
      setAction("buyNow");
    }
  };

  // const handleRadioChange = (event, id) => {
  //   const value = event.target.value;
  //   setCheckedValue(value);
  //   setvariantId(id);
  //   WishlistApi(id);
  //   let setFilterSKU = product.extras[0].color_list?.filter((item) => {
  //     return item.sku === value;
  //   });
  //   setproductShade(setFilterSKU[0].color_name);

  //   window.YMK.applyMakeupBySku(setFilterSKU[0].sku);
  //   console.log("SHADER", id, value, setFilterSKU);
  // };

  const handleRadioChange = (event, id) => {
    // console.log("handleRadioChange", value);

    const value = event.target.value;
    setCheckedValue(value);
    setvariantId(id);

    // Fetch the shade from the product extras
    let setFilterSKU;

    if (product.extras[0].color_list) {
      setFilterSKU = product.extras[0].color_list?.find(
        (item) => item.sku === value
      );
    } else {
      setFilterSKU = product.extras[0].find((item) => item.sku === value);
    }

    if (setFilterSKU) {
      setproductShade(setFilterSKU.color_name); // Set the new shade
     
      window.YMK.applyMakeupBySku(setFilterSKU.sku,(t)=>{
        console.log("applyMakeupBySku>>>",t);
      }); // Apply the makeup SKU
      console.log("SHADER", id, value, setFilterSKU);
    }
  };

  // Ensure that the product shade is reflected in the UI
  useEffect(() => {
    console.log("Current product shade:", productShade);
  }, [productShade]);

  let user = localStorage.getItem("userId");

  const urlParams = new URLSearchParams(window.location.search);
  const paramProductId = urlParams.get("product");
  const ProductId = sessionStorage.getItem("FoyCurrProduct");

  // useEffect(() => {
  //   if (paramProductId) {
  //     sessionStorage.setItem("FoyCurrProduct", paramProductId);
  //   } else if (!paramProductId && ProductId) {
  //     sessionStorage.setItem("FoyCurrProduct", ProductId);
  //   }
  // }, [paramProductId]);

  useEffect(() => {
    console.log("ProductId1>>>",ProductId)
    if (ProductId === "null") {
      console.log("productset 2");
      sessionStorage.removeItem("FoyCurrProduct");
      // Stay on the home page if ProductId is null or "null"
      if (window.location.pathname !== "/") {
        window.location.href = "/"; // Redirect to home
      }
    } else {
      console.log("productset 3");
      if(!ProductId || ProductId == null){
        console.log("ProductId2>>>",ProductId)
        const searchParams = new URLSearchParams(window.location.search);
        const newProductId = searchParams.get("product");
        let url = user
        ? `/?product=${newProductId}&userId=${user}`
        // : `/?product=${newProductId}&ymk=true`;
        : `/?product=${newProductId}`;
        console.log("productset 4",newProductId);
        fetchData(newProductId);
      if (window.location.href !== url) {
        window.history.pushState({}, "", url); // Use replaceState to avoid adding a new entry to the history
        window.dispatchEvent(new PopStateEvent("popstate"));
      }
      }
      else{
        console.log("paramProductId>>",paramProductId);
        if(paramProductId != ProductId ){
          console.log("paramProductId >> true")
          let url = user
          ? `/?product=${paramProductId}&userId=${user}`
          // : `/?product=${paramProductId}&ymk=true`;
          : `/?product=${paramProductId}`;
          fetchData(paramProductId);
          if (window.location.href !== url) {
            window.history.pushState({}, "", url); // Use replaceState to avoid adding a new entry to the history
            window.dispatchEvent(new PopStateEvent("popstate"));
          }
        }
        else {
          console.log("productset 4",ProductId);
          console.log("ProductId3>>>",ProductId)
        let url = user
          ? `/?product=${ProductId}&userId=${user}`
          // : `/?product=${ProductId}&ymk=true`;
          : `/?product=${ProductId}`;
          fetchData(ProductId);
        if (window.location.href !== url) {
          window.history.pushState({}, "", url); // Use replaceState to avoid adding a new entry to the history
          window.dispatchEvent(new PopStateEvent("popstate"));
        }
        }
      
    }
    }
  }, []);

  async function fetchData(id) {
    // window.YMK.reset();
    setFilter(false);
    setStaticFilter(false);
    setLoading(true);
    setShowSimilarProducts(false);
    window.scrollTo(0, 0);

    sessionStorage.setItem("FoyCurrProduct", id);
    const ProductId = id;


    // Call this function whenever you change the "id"
    // For example: handleIdChange(newId);

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("authorization", "your_auth_token_here");

      var raw = JSON.stringify({
        product_id: id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        "https://foyforyou.com/foyapi/api/customer/Product/GetProductByParameters",
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      const brandId = result?.data[0]?.brand_id;
      const categoryId = result?.data[0]?.category_id;
      const subCatId = result?.data[0]?.sub_category_id;
      localStorage.setItem("categoryId", categoryId);
      localStorage.setItem("brandId", brandId);
      localStorage.setItem("subCatId", subCatId);

      let product = result.data[0];
      setvariantId(product.id);
      console.log("product", product);
      let productVariants = product?.extras;
      let productVariantColor = productVariants[0]?.color_list;
      let discountList = productVariantColor[0]?.discountList;
      let percentageDiff = parseInt(discountList[0]?.percentage);
      var variantPrices;

      // console.log("product.list_price>>>>", typeof product.list_price);
      if (percentageDiff) {
        // console.log("percentageDiff>>>", percentageDiff);
        var variantPrice2 = (product.list_price * percentageDiff) / 100;
        variantPrices = (product.list_price - variantPrice2).toFixed();
        setVariantDiscount(percentageDiff);
        setVariantPrice(variantPrices);
        // console.log("variantPrice>>>>", variantPrice);
      }
      console.log(
        "discountList",
        discountList,
        "percentageDiff>>>",
        variantDiscount
      );

      
      setProduct(product);
      setvariantColors(productVariantColor);
      setproductShade(product.extras[0].color_list[0].color_name);
      setLoading(false);
      setFilter(false);
      window.YMK.reset();
      
      let url = user
        ? `/?product=${ProductId}&userId=${user}`
        : //  : `/?product=${ProductId}&ymk=true`;
          `/?product=${ProductId}`;

      window.history.pushState({}, "", url);
      window.dispatchEvent(new PopStateEvent("popstate"));
    } catch (error) {
      console.log("Error:", error);
      setLoading(false);
    }
  }

  console.log("product", product);

  async function findFilters(staticFilter, staticFilterval) {
    setLoading(true);
    var payload;
    payload = JSON.stringify(staticFilterval);
    // console.log("staticFilterval>>>", staticFilterval);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://foyforyou.com/foyapi/api/customer/Product/fetchProductByParams",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    axios
      .request(config)
      .then((response) => {
        console.log("response?.data.data>>>", response?.data?.data);
        setfilterProducts(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setFilter(true);
      });
  }

  // useEffect(() => {
  //   if (productID !== 0) {
  //     fetchData(productID);
  //   }
  // }, [productID, filterProducts]);

  const handleCategoryClick = (category, id) => {
    // alert();
    // console.log("handleclikkkk",category,"category>>>",id);
    setActiveCategory(category);
    setStaticFilterval({ ...staticFilterval, sub_category_id: id });
    // console.log("staticFilterval>>",staticFilter)
    setStaticFilter(false);
  };

  const redirectPdpPage = () => {
    const proId = variantId;
    const proTitle = product.name.replaceAll(/[ ()\.]/g, "_");

    // console.log("productTitle>>",`https://foyforyou.com/product-detail-updated/${proId}/${proTitle}`)
    window.location.href = `https://foyforyou.com/pdp/Makeup/${product?.id}/${proTitle}`;
  };
  // wishlist apis

  useEffect(() => {
    WishlistApi();
  }, []);

  const [wishlistDataID, setWishlistDataID] = useState([]);

  // console.log("=====countcountcountcount===========>", wishlistDataID);

  // useEffect(() => {
  //   WishlistApi();
  //   console.log("wishlistData>>>", wishlistDataSet);
  // }, [wishlistfound, variantId]);

  // console.log("product", product);
  // wishlist apis code ends here
  const [isInWishlist, setIsInWishlist] = useState(false);
  useEffect(() => {
    if (wishlistDataSet) {
      const isExist = wishlistDataSet.some(
        (item) => item.product_id === product?.id
      );
      setIsInWishlist(isExist);
    }
  }, [wishlistDataSet]);

  // ---------------- OPEN SIMILAR PRODUCTS ----------------

  const [showSimilarProducts, setShowSimilarProducts] = useState(false);

  // const handlers = useSwipeable({
  //   onSwiping: (eventData) => setShowSimilarProducts(true),
  //   onSwipedDown: (eventData) => setShowSimilarProducts(false),
  //   swipeDuration: 500,
  //   preventScrollOnSwipe: true,
  //   // trackMouse: true,
  //   // delta: 30,
  //   preventScrollOnSwipe: false,
  //   // ...config,
  // });
  const handlers = useSwipeable({
    // onSwiping: () => setShowSimilarProducts(false),
    onSwipedUp: () => setShowSimilarProducts(true),
    onSwipedDown: () => setShowSimilarProducts(false),
    // swipeDuration: 500,
    // preventScrollOnSwipe: true,
    delta: 600,
  });

  const upHandler = useSwipeable({
    onSwipedUp: () => setShowSimilarProducts(true),
  });
  const myRef = React.useRef();

  const refPassthrough = (el) => {
    // call useSwipeable ref prop with el
    handlers.ref(el);

    // set myRef el so you can access it yourself
    myRef.current = el;
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // Check if user has scrolled to the bottom on mobile devices
      if (window.innerWidth <= 768) {
        if (scrollTop + windowHeight >= documentHeight - 10) {
          setShowSimilarProducts(true);

          // Optionally, smooth scroll adjustment (can be removed if not needed)
          window.scrollTo({
            top: scrollTop - 0, // No adjustment, so the smooth scroll does nothing here
            behavior: "smooth",
          });
        }
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log("product>>>>", product);

  // const FilterHandler = () => {
  //   // fetchData(11112);
  // };

  return (
    <div className="layout">
      {loading && (
        <>
          {" "}
          <div className="loaderContainer">
            <img className="loader" src="/loader.svg" alt="loader" />
          </div>
        </>
      )}
      <Header
        isOverlay={isOverlay}
        setPoputrue={setPoputrue}
        atcTrue={atcTrue}
        wishlistTrue={wishlistTrue}
        variantId={variantId}
        counts={counts}
        cartCounts={cartCounts}
        cartData={cartData}
        cartDataApi={cartDataApi}
        windowWidth={windowWidth}
      />
      {windowWidth <= 768 ? (
        <>
          {poputrue && (
            <LoginPopup
              PopTrue={poputrue}
              action={action}
              onclose={onclosePopup}
              handleAddToCart={handleAddToCart}
              WishlistApi={WishlistApi}
              handleButNow={handleButNow}
              redirect={redirect}
              variantId={variantId}
              WishlistApi={WishlistApi}
            />
          )}
          {atcPopup && (
            <>
              <div className="popup_atc_overlay">
                <div className="popAtc">
                  <p>
                    <span>
                      <img src="/atc.png" />
                    </span>
                    {popmsg}
                  </p>
                </div>
              </div>
            </>
          )}
          <div className="btnProduct d-flex ">
            <div>
              <button
                className="wishlistIcon"
                onClick={(e) => {
                  setRedirect("addwishlist");
                  setvariantId(product?.id);
                  handleAddToCart(e, !isInWishlist, product?.id);
                  // console.log(!isInWishlist, product?.id);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 30 30"
                  fill={isInWishlist ? "#fff" : "none"}
                  height={30}
                  width={30}
                >
                  <path
                    d="M5.56335 17.3853L14.3153 25.6068C14.6398 25.9116 14.802 26.064 15 26.064C15.198 26.064 15.3602 25.9116 15.6847 25.6068L24.4367 17.3853C26.8819 15.0882 27.1788 11.3082 25.1223 8.65758L24.7356 8.15918C22.2753 4.98823 17.337 5.52002 15.6083 9.14206C15.3641 9.6537 14.6359 9.6537 14.3917 9.14206C12.663 5.52002 7.72465 4.98823 5.26443 8.15918L4.87773 8.65759C2.82118 11.3083 3.11813 15.0882 5.56335 17.3853Z"
                    stroke="#FFF"
                    strokeWidth="2"
                  />
                </svg>
              </button>
            </div>
            <div className="buynowWrapper">
              <button
                className="buy_now font-RedHat border"
                onClick={() => {
                  handleButNow();
                  setvariantId(product?.id);
                }}
                style={{ whiteSpace: "nowrap" }}
              >
                Buy now
              </button>
            </div>
            <div>
              <button
                className="add_to_cart font-RedHat"
                onClick={(e) => {
                  handleAddToCart(e, false, product?.id);
                }}
              >
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 20 16"
                    fill="none"
                  >
                    <path
                      d="M11.1092 14.1667V5.83334"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M7.18068 10L15.0378 10"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </span>
                <span>ADD to cart</span>
              </button>
            </div>
          </div>
          <div className="main-component-wrapper Sticky">
            <div className="ymkComponents d-flex  justify-content-center">
              <div id="YMK-module"></div>
            </div>
            <div className="ProductOuter container">
              <div className="col-12 ProductContent d-flex flex-wrap">
                <div className="d-flex flex-wrap col-12">
                  <div className="w-100 d-flex flex-wrap justify-content-between align-items-center pb-md-3 pb-2">
                    <div className="d-flex align-items-center ">
                      <div className="backArrow" onClick={redirectPdpPage}>
                        <img src="./backArrow.svg" />
                      </div>
                      <p className="font-RedHat m-0 brand_type fw-bold">
                        {productShade}
                      </p>
                    </div>
                    {/* <button onClick={FilterHandler}>Update</button> */}
                    <div className="filter_Text filter_mob">
                      <button className="font-RedHat" onClick={HandleFilter}>
                        Filter
                      </button>
                    </div>
                  </div>
                  <div className="ProductShades col-12">
                    <div className="color-grid">
                      <div className="varinatsShade">
                        {variantColors &&
                          variantColors.map((item, index) => {
                            console.log("variantColors", item); 
                            return (
                              <label
                                key={index + 1}
                                htmlFor={item.color ? item.color : "#000"}
                                title={
                                  item.color_name ? item.color_name : "Default"
                                }
                                className={`${
                                  checkedValue === item.sku ? "borderShade" : ""
                                }`}
                              >
                                <div
                                  style={{
                                    background: `${
                                      item.color ? item.color : "#000"
                                    }`,
                                  }}
                                ></div>
                                <input
                                  type="radio"
                                  name="one"
                                  id={ item.color ? item.color : "#000"}
                                  value={item.sku}
                                  checked={checkedValue === item.sku} // Changed from defaultChecked to checked
                                  onChange={(e) => {
                                    handleRadioChange(e, item.id);
                                    
                                  }}
                                />
                              </label>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main_prod_grid">
                  <div className="prod_title_grid">
                    {/* <button
                      className="testbtn"
                      onClick={(e) => window.YMK.reset()}
                    >
                      Reset
                    </button> */}
                    <div className="TitleWidth">
                      <h6 className="font-RedHat m-0 brand_name py-2">
                        {product && product?.brand?.name
                          ? product?.brand?.name
                          : ""}
                      </h6>
                      <p className="font-Harmonia product_name fw-bold m-0 py-md-2">
                        {product && product?.name
                          ? product?.name
                          : "Soft Blur Cream Concealer & Foundation with SPF 50"}
                      </p>
                    </div>
                    <div className="qtyPrice2 py-2">
                      <div
                        className="PriceContent"
                        style={{ justifyContent: "end" }}
                      >
                        {variantDiscount === 0 ? (
                          ""
                        ) : (
                          <p className="discount m0 font-RedHat">
                            {variantDiscount}%
                          </p>
                        )}

                        {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                        <div className="" style={{ fontWeight: "900" }}>
                          {variantPrice
                            ? `₹${variantPrice == 0 ? " " : variantPrice}`
                            : ""}
                        </div>
                        {product?.list_price !== 0 && (
                          <div
                            className={
                              variantDiscount === 0
                                ? "procomparePrice  m0 font-RedHat"
                                : "comparePrice procomparePrice  m0 font-RedHat"
                            }
                          >
                            ₹{product?.list_price}
                          </div>
                        )}
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="btnProduct d-flex justify-content-between">
                    <div>
                      <button
                        className="wishlistIcon"
                        onClick={(e) => {
                          setRedirect("addwishlist");
                          setvariantId(product?.id);
                          handleAddToCart(e, !isInWishlist, product?.id);
                          // console.log(!isInWishlist, product?.id);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 30 30"
                          fill={isInWishlist ? "#fff" : "none"}
                          height={30}
                          width={30}
                        >
                          <path
                            d="M5.56335 17.3853L14.3153 25.6068C14.6398 25.9116 14.802 26.064 15 26.064C15.198 26.064 15.3602 25.9116 15.6847 25.6068L24.4367 17.3853C26.8819 15.0882 27.1788 11.3082 25.1223 8.65758L24.7356 8.15918C22.2753 4.98823 17.337 5.52002 15.6083 9.14206C15.3641 9.6537 14.6359 9.6537 14.3917 9.14206C12.663 5.52002 7.72465 4.98823 5.26443 8.15918L4.87773 8.65759C2.82118 11.3083 3.11813 15.0882 5.56335 17.3853Z"
                            stroke="#FFF"
                            strokeWidth="2"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="buynowWrapper">
                      <button
                        className="buy_now font-RedHat border"
                        onClick={() => {
                          handleButNow();
                          setvariantId(product?.id);
                        }}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Buy now
                      </button>
                    </div>
                    <div>
                      <button
                        className="add_to_cart font-RedHat"
                        onClick={(e) => {
                          handleAddToCart(e, false, product?.id);
                        }}
                      >
                        <span className="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 20 16"
                            fill="none"
                          >
                            <path
                              d="M11.1092 14.1667V5.83334"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <path
                              d="M7.18068 10L15.0378 10"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        </span>
                        <span>ADD to cart</span>
                      </button>
                    </div>
                  </div> */}
                  <div className="faceTone mb-3">
                    <h5 className="font-Harmonia pt-3 m-0">
                      Other Categories For Virtual Make-up Try-on:
                    </h5>
                    <div className="toneType d-flex justify-content-between py-3">
                      <button
                        className={`toneText font-RedHat ${
                          activeCategory === "LIPS" ? "firstTone" : ""
                        }`}
                        onClick={() => handleCategoryClick("LIPS", "14")}
                      >
                        LIPS
                      </button>
                      <button
                        className={`toneText font-RedHat ${
                          activeCategory === "Eyes" ? "firstTone" : ""
                        }`}
                        onClick={() => handleCategoryClick("Eyes", "12")}
                      >
                        Eyes
                      </button>
                      <button
                        className={`toneText font-RedHat ${
                          activeCategory === "Face" ? "firstTone" : ""
                        }`}
                        onClick={() => handleCategoryClick("Face", "13")}
                      >
                        Face
                      </button>
                    </div>
                    {activeCategory === "LIPS" && (
                      <Lips subcategoryFilter={subcategoryFilter} />
                    )}
                    {activeCategory === "Eyes" && (
                      <Eyes subcategoryFilter={subcategoryFilter} />
                    )}
                    {activeCategory === "Face" && (
                      <Face subcategoryFilter={subcategoryFilter} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {filter === true && (
            <Filter
              cleverTapEvent={cleverTapEvent}
              prop={true}
              sendToParent={setFilter}
              setProduct={productIdFromFilter}
              // filters={selectedFilters}
              staticFilter={staticFilter}
              filterdata={filterProducts}
              leafValues={leafValues}
              setPoputrue={setPoputrue}
              wishlistDataSet={wishlistDataSet}
              handleAddToCart={handleAddToCart}
              product={product}
              fetchData={fetchData}
            />
          )}

          <div
            id="similarProduct"
            className="Sticky1 similiarProductContainer"
            style={{ marginTop: "20px" }}
          >
            <SimilarProducts
              handlers={handlers}
              upHandler={upHandler}
              showSimilarProducts={showSimilarProducts}
              setShowSimilarProducts={setShowSimilarProducts}
              cleverTapEvent={cleverTapEvent}
              handleAddToCart={handleAddToCart}
              wishlistDataSet={wishlistDataSet}
              fetchData={fetchData}
            />
          </div>
        </>
      ) : (
        <>
          <DesktopCompt />
        </>
      )}
    </div>
  );
};
export default Product;
