import React from "react";
import "../styles/product.css";
import lipsfilter from "../filters/Lips/index.json";

const Lips = ({ subcategoryFilter}) => {
  const selectedVal = localStorage.getItem("selectedVal")
  return (
    <div>
      {/* <div className="toneLips d-flex"> */}
      <div
        className=" "
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        {lipsfilter["lips"].length !== 0
          ? lipsfilter["lips"].map((filter, key) => {
              return (
                <div className="toneLipsdata font-RedHat" key={key}>
                  <label className="filter_name 2">
                    <input
                      type="radio"
                      value={filter.value}
                      name={filter.name}
                      checked={selectedVal === filter.value}
                      onChange={(e) => {
                        subcategoryFilter(e);
                      }}
                    />
                    {filter.filter_name}
                    <div className="boxButton"></div>
                  </label>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default Lips;
