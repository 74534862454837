import React from 'react'

const Ourstory = () => {
  return (
    <div>
        this is our story page
    </div>
  )
}

export default Ourstory
