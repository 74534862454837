import React, { useEffect, useState } from "react";
import "../styles/cart.css";
import axios from "axios";

export default function Cart({ cartData, cartDataApi, handleShopping }) {
  const [qty, setQty] = useState([]);
  const [loading, setLoading] = useState(false);
  const [atcPopup, setAtcPopup] = useState(false);
  const [popmsg, setPopmsg] = useState("");
  const [couponPopup, setcouponPopup] = useState(false);
  const [couponData, setcouponData] = useState([]);
  const [couponApplyLoading, setcouponApplyLoading] = useState(false);
  const [couponApplied, setCouponApplied] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [couponType, setCouponType] = useState("percentage");
  const [message, setmessage] = useState("");
  const userId = localStorage.getItem("userID");

  const manageCart = (action, proid, quanity, id) => {
    setLoading(true);
    setAtcPopup(true);
    console.log("action>>>", action);
    var newQty;
    if (action == "plusQuantity") {
      newQty = quanity + 1;
      setPopmsg("Item has been added in cart");
    } else if (action == "minusQuantity") {
      newQty = quanity - 1;
      setPopmsg("Item has been removed in cart");
    } else {
      newQty = 0;
      setPopmsg("Item has been added in cart");
    }

    setTimeout(() => {
      setAtcPopup(false);
    }, 1500);
    console.log("newQty>>", newQty);
    setQty(newQty);
    const payload = {
      id: id,
      product_id: proid,
      user_id: userId,
      qty: newQty,
      iswishlistitem: false,
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://foyforyou.com/foyapi/api/customer/ManageCart/ManageCartByUser",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    axios
      .request(config)
      .then((response) => {
        console.log("response", response.data);
        localStorage.setItem("cartUpdate", true);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error: ", error);
      })
      .finally(() => {
        cartDataApi();
      });
  };
  useEffect(() => {
    cartDataApi();
  }, []);

  let totalLinePrice = 0;
  let shippingPrice = 70;
  let finalPrice = 0;

  const fetchCouponData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://foyforyou.com/foyapi/api/Discount/GetDiscountFromProductId?ProductIds=11202&BrandIds=11,10017,10017,11,10017,10017&CategoryIds=1",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setcouponData(response?.data?.data[0]?.discounts);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCouponData();
  }, []);

  // const applyCouponCodeHandle = (couponCode) => {
  //   console.log("applyCouponCodeHandle", couponCode);
  //   setcouponApplyLoading(true);
  //   try {
  //     let config = {
  //       method: "post",
  //       maxBodyLength: Infinity,
  //       url: `https://foyforyou.com/foyapi/api/Discount/GetDiscountByDiscount_Code?discount_code=${couponCode}&userId=${userId}`,
  //       headers: {},
  //     };

  //     axios
  //       .request(config)
  //       .then((response) => {
  //         console.log("applyCouponCodeHandle", response.data);
  //         cartDataApi();
  //         setcouponApplyLoading(false);
  //         setCouponApplied(`${couponCode}`);
  //         setcouponPopup(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } catch (error) {}
  // };

  // const applyCouponCodeHandle = (couponCode) => {
  //   setcouponApplyLoading(true);

  //   try {
  //     let config = {
  //       method: "post",
  //       maxBodyLength: Infinity,
  //       url: `https://foyforyou.com/foyapi/api/Discount/GetDiscountByDiscount_Code?discount_code=${couponCode}&userId=${userId}`,
  //       headers: {},
  //     };

  //     axios
  //       .request(config)
  //       .then((response) => {
  //         console.log("applyCouponCodeHandle", response.data);
  //         const couponData = response?.data?.data[0];

  //         console.log("applyCouponCodeHandle couponData", couponData);

  //         if (couponData && couponData.percentage > 0) {
  //           const discountPercentage = couponData.percentage;
  //           const minimumCartAmount = couponData.minimum_cart_amount || 0;

  //           if (totalLinePrice >= minimumCartAmount) {
  //             const discountAmount =
  //               (totalLinePrice * discountPercentage) / 100;
  //             setDiscountedPrice(discountAmount);
  //             setCouponType("percentage");
  //           } else {
  //             setcouponApplyLoading(false);
  //             // Display a message that the minimum cart amount is not reached
  //             setmessage("Minimum cart amount is not reached");
  //             // console.log("Minimum cart amount not reached");
  //           }
  //         } else if (couponData && couponData.amount > 0) {
  //           const discountAmount = couponData.amount;
  //           setDiscountedPrice(discountAmount);
  //           setCouponType("amount");
  //         }

  //         cartDataApi();
  //         setcouponApplyLoading(false);
  //         setCouponApplied(`${couponCode}`);
  //         setcouponPopup(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } catch (error) {}
  // };

  const applyCouponCodeHandle = (couponCode) => {
    setcouponApplyLoading(true);
    setmessage("");

    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://foyforyou.com/foyapi/api/Discount/GetDiscountByDiscount_Code?discount_code=${couponCode}&userId=${userId}`,
        headers: {},
      };

      axios
        .request(config)
        .then((response) => {
          //   console.log("applyCouponCodeHandle", response.data);
          //   const couponData = response?.data?.data[0];

          //   console.log("applyCouponCodeHandle couponData", couponData);

          //   if (couponData) {
          //     const discountPercentage = couponData.percentage || 0;
          //     const discountAmount = couponData.amount || 0;
          //     const minimumCartAmount = couponData.minimum_cart_amount || 0;
          //     const minimumCartLinePrice = couponData.minimum_cart_line_price || 0;

          //     if (totalLinePrice >= minimumCartAmount) {
          //       let appliedDiscount = 0;

          //       if (discountPercentage > 0) {
          //         appliedDiscount = (totalLinePrice * discountPercentage) / 100;
          //         setCouponType("percentage");
          //       } else if (discountAmount > 0) {
          //         appliedDiscount = discountAmount;
          //         setCouponType("amount");
          //       }

          //       if (appliedDiscount > 0) {
          //         setDiscountedPrice(appliedDiscount);
          //         cartDataApi();
          //         setCouponApplied(`${couponCode}`);
          //         setcouponPopup(false);
          //       } else {
          //         setcouponApplyLoading(false);
          //         // Display a message that the minimum cart amount or line price is not reached
          //         setmessage("Minimum cart amount or line price is not reached");
          //       }
          //     } else {
          //       setcouponApplyLoading(false);
          //       // Display a message that the minimum cart amount or line price is not reached
          //       setmessage("Minimum cart amount or line price is not reached");
          //     }
          //   } else {
          //     setcouponApplyLoading(false);
          //     // Display a message that the coupon data is not valid
          //     setmessage("Invalid coupon data");
          //   }
          // })

          setcouponApplyLoading(false);
          console.log("applyCouponCodeHandle", response.data);
          const couponData = response?.data?.data[0];

          console.log("applyCouponCodeHandle couponData", couponData);

          if (couponData) {
            const discountPercentage = couponData.percentage || 0;
            const discountAmount = couponData.amount || 0;
            const minimumCartAmount = couponData.minimum_cart_amount || 0;
            const minimumCartLinePrice =
              couponData.minimum_cart_line_price || 0;

            console.log("Total Line Price:", totalLinePrice);
            console.log("Minimum Cart Amount from API:", minimumCartAmount);

            if (totalLinePrice >= minimumCartAmount) {
              let appliedDiscount = 0;

              if (discountPercentage > 0) {
                appliedDiscount = (totalLinePrice * discountPercentage) / 100;
                setCouponType("percentage");
              } else if (discountAmount > 0) {
                appliedDiscount = discountAmount;
                setCouponType("amount");
              }

              if (appliedDiscount > 0) {
                setDiscountedPrice(appliedDiscount);
                cartDataApi();
                setCouponApplied(`${couponCode}`);
                setcouponPopup(false);
              } else {
                setcouponApplyLoading(false);
                // Display a message that the minimum cart amount or line price is not reached
                setmessage("Minimum cart amount or line price is not reached");
                setcouponPopup(false);
              }
            } else {
              setcouponApplyLoading(false);
              // Display a message that the minimum cart amount or line price is not reached
              setmessage("Minimum cart amount or line price is not reached");
              setcouponPopup(false);
            }
          } else {
            setcouponApplyLoading(false);
            // Display a message that the coupon data is not valid
            setmessage("Invalid coupon data");
            setcouponApplyLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  };

  cartData &&
    cartData?.forEach((element) => {
      totalLinePrice =
        totalLinePrice + element.productdetail?.your_price * element.qty;
    });

  if (totalLinePrice > 499) {
    finalPrice = totalLinePrice + 0;
  } else {
    finalPrice = totalLinePrice + shippingPrice;
  }

  console.log("couponData", couponData);
  console.log("cartData", cartData);
  console.log("totalLinePrice", totalLinePrice);
  // console.log("totalPrice",typeof totalPrice);

  return (
    <>
      {loading && (
        <>
          {" "}
          <div className="loaderContainer">
            <img className="loader" src="/loader.svg" alt="loader" />
          </div>
        </>
      )}
      {atcPopup && (
        <>
          <div className="popup_atc_overlay">
            <div className="popAtc">
              <p>
                <span>
                  <img src="/atc.png" />
                </span>
                {popmsg}
              </p>
            </div>
          </div>
        </>
      )}
      {cartData ? (
        <>
          <div className="cart-title-detail font-RedHat">
            <div className="cart-title">
              <h4>YOUR CART</h4>
              {/* <span>
            you got <b>FREE SHIPPING!</b>
          </span> */}
            </div>
            <div className="cart-right-image">
              <img src="/cart-icon.png" alt="Cart" title="Cart" />
            </div>
          </div>
          <div className="cart-content font-RedHat">
            <div className="CartDetailsC">
              {cartData.map((item, key) => {
                {
                  /* totalPrice =
                  totalPrice + item.productdetail?.your_price * item.qty; */
                }
                return (
                  <div className="CartItem" key={key}>
                    <div className="CartItem__ImageWrapper">
                      <div className="cartItem_image">
                        <img
                          src={item.productdetail?.imageList[0].path}
                          alt="Product"
                          title="Product"
                        />
                      </div>
                    </div>
                    <div className="CartItem__Info">
                      <div className="CartItem__Meta">
                        <p className="CartItem__Title font-RedHat">
                          {item.productdetail?.name}
                        </p>
                      </div>
                      <div className="CartItem__Actions">
                        <div className="CartItem__PriceList">
                          <div className="CartItem__Price">
                            <div className="PriceContent font-Harmonia">
                              <span>₹{item.productdetail?.your_price}</span>
                            </div>
                          </div>
                        </div>
                        <div className="CartItem__QuantitySelector">
                          <div className="QuantitySelector">
                            <div className="qtyPrice">
                              <div className="qtyContent">
                                <span
                                  className="qtyminus"
                                  onClick={(e) => {
                                    manageCart(
                                      "minusQuantity",
                                      item.productdetail?.id,
                                      item.qty,
                                      item.id
                                    );
                                  }}
                                >
                                  <svg
                                    className="Icon Icon--minus "
                                    role="presentation"
                                    viewBox="0 0 16 2"
                                  >
                                    <path
                                      d="M1,1 L15,1"
                                      stroke="currentColor"
                                      fill="none"
                                      fillRule="evenodd"
                                      strokeLinecap="square"
                                    ></path>
                                  </svg>
                                </span>
                                <span>{item.qty}</span>
                                <span
                                  className="qtyplus"
                                  onClick={(e) => {
                                    manageCart(
                                      "plusQuantity",
                                      item.productdetail?.id,
                                      item.qty,
                                      item.id
                                    );
                                  }}
                                >
                                  <svg
                                    className="Icon Icon--plus "
                                    role="presentation"
                                    viewBox="0 0 16 16"
                                  >
                                    <g
                                      stroke="currentColor"
                                      fill="none"
                                      fillRule="evenodd"
                                      strokeLinecap="square"
                                    >
                                      <path d="M8,1 L8,15"></path>
                                      <path d="M1,8 L15,8"></path>
                                    </g>
                                  </svg>
                                </span>
                              </div>
                              <div
                                className="DeleteItem"
                                onClick={(e) => {
                                  manageCart(
                                    "delete",
                                    item.productdetail?.id,
                                    item.qty,
                                    item.id
                                  );
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13"
                                  height="16"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M4.886 0c-.572 0-1.043.471-1.043 1.043v.696H1.125a.348.348 0 0 0-.14 0h-.62a.348.348 0 1 0 0 .696h.347v12.522c0 .572.471 1.043 1.044 1.043h9.043c.573 0 1.044-.471 1.044-1.043V2.435h.348a.348.348 0 1 0 0-.696H8.712v-.696C8.712.471 8.242 0 7.67 0H4.886zm0 .696H7.67c.193 0 .348.155.348.347v.696h-3.48v-.696c0-.192.155-.347.348-.347zM1.408 2.435h9.74v12.522a.347.347 0 0 1-.349.347H1.756a.347.347 0 0 1-.348-.347V2.435zm2.75 1.728a.348.348 0 0 0-.043.01.348.348 0 0 0-.272.349v8.695a.348.348 0 1 0 .695 0V4.522a.348.348 0 0 0-.38-.359zm2.087 0a.348.348 0 0 0-.044.01.348.348 0 0 0-.271.349v8.695a.348.348 0 1 0 .695 0V4.522a.348.348 0 0 0-.38-.359zm2.087 0a.348.348 0 0 0-.044.01.348.348 0 0 0-.271.349v8.695a.348.348 0 1 0 .695 0V4.522a.348.348 0 0 0-.38-.359z"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              className="offerContainer"
              onClick={() => setcouponPopup(true)}
            >
              <div className="offerTextContainer">
                <img
                  src="/offer-checkout.png"
                  alt=""
                  width="100%"
                  height="100%"
                />
                {couponApplied != "" ? (
                  <>
                    <p className="offerText">{couponApplied}</p>
                    <p className="appliedText">Applied</p>
                  </>
                ) : (
                  <p className="offerText">OFFERS</p>
                )}
              </div>
              <div>
                <svg
                  xlink="http://www.w3.org/1999/xlink"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.21209 0.337088C1.5782 -0.0290289 2.1718 -0.0290289 2.53791 0.337088L7.53791 5.33709C7.90403 5.7032 7.90403 6.2968 7.53791 6.66291L2.53791 11.6629C2.1718 12.029 1.5782 12.029 1.21209 11.6629C0.845971 11.2968 0.845971 10.7032 1.21209 10.3371L5.54917 6L1.21209 1.66291C0.84597 1.2968 0.84597 0.703203 1.21209 0.337088Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            <div className="removeCouponWrapper">
              {couponApplied ? (
                <button
                  className="removeCouponButton"
                  onClick={() => {
                    setDiscountedPrice(0);
                    setCouponApplied("");
                  }}
                >
                  Remove Offer
                </button>
              ) : null}
            </div>

            <div>
              <p>{message}</p>
            </div>

            <div className="CartPrice__details ">
              <div className="CartPriceHeading">
                <div className="CartHeadinGDetails">
                  <h6 className="CartHD">Price Details</h6>
                  <div className="cart-price-text">MRP</div>
                  {discountedPrice > 0 ? (
                    <div className="cart-price-text">Coupon Discount</div>
                  ) : null}

                  <div className="cart-price-text">Shipping</div>
                  <div className="cart-price-heading">You Pay</div>
                </div>
                <div className="CartTitleDetails CartRightDetails">
                  <h6 className="CartHD">Price Details</h6>
                  <div className="cart-price-text">₹{totalLinePrice}</div>
                  {discountedPrice > 0 ? (
                    <div className="cart-price-text">₹{discountedPrice}</div>
                  ) : null}

                  {totalLinePrice > 499 ? (
                    <div className="cart-price-text">₹0</div>
                  ) : (
                    <div className="cart-price-text">
                      {totalLinePrice > 0 ? `₹{shippingPrice}` : 0}
                    </div>
                  )}

                  <div className="cart-price-heading">₹{totalLinePrice}</div>
                </div>
              </div>
            </div>
            <div className="CartSubTotal">
              <div className="CartHD">Grand Total</div>
              {/* <div className="CartHD">₹{finalPrice}</div> */}
              {couponApplied ? (
                <>
                  <div className="CartHD">
                    ₹{finalPrice - discountedPrice}
                    {/* {couponType === "percentage" && `% off`} */}
                  </div>

                  {/* <button
                    onClick={() => {
                      setDiscountedPrice(0);
                      setCouponApplied("");
                    }}
                  >
                    Remove
                  </button> */}
                </>
              ) : (
                <div className="CartHD">₹{finalPrice}</div>
              )}
            </div>
            <div className="CartCheckoutButton">
              <button>
                <a
                  href="https://foyforyou.com/checkout-new"
                  className="checkoutBtn"
                >
                  Checkout
                </a>
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="emptyCartContent">Your Cart is Empty</div>
        </>
      )}
      {couponPopup === true ? (
        <div className="couponsContainerWrapper">
          {couponApplyLoading === true ? (
            <>
              <div className="applyCouponSpinerOverlay"></div>
              <div className="applyCouponSpiner">
                <svg
                  version="1.1"
                  id="L9"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40px"
                  height="40px"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 100"
                  enable-background="new 0 0 0 0"
                  space="preserve"
                >
                  <path
                    fill="#fff"
                    d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                  >
                    <animateTransform
                      attributeName="transform"
                      attributeType="XML"
                      type="rotate"
                      dur="1s"
                      from="0 50 50"
                      to="360 50 50"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>{" "}
              </div>
            </>
          ) : null}

          <div className="backToCartButtonContainer">
            <button
              className="backToCartButton"
              onClick={() => setcouponPopup(false)}
            >
              <svg
                xlink="http://www.w3.org/1999/xlink"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="16"
                viewBox="0 0 32 23"
                fill="none"
              >
                <path
                  d="M12.9977 3.09975C13.7187 2.39064 13.7187 1.24094 12.9977 0.531832C12.2768 -0.177277 11.1078 -0.177277 10.3869 0.531832L0.540726 10.216C0.180242 10.5706 0 11.0353 0 11.5C0 11.7462 0.0498193 11.981 0.140084 12.1951C0.230176 12.4092 0.363723 12.6099 0.540726 12.784L10.3869 22.4682C11.1078 23.1773 12.2768 23.1773 12.9977 22.4682C13.7187 21.7591 13.7187 20.6094 12.9977 19.9003L6.30316 13.3158H30.1538C31.1734 13.3158 32 12.5028 32 11.5C32 10.4972 31.1734 9.68421 30.1538 9.68421H6.30316L12.9977 3.09975Z"
                  fill="black"
                />
              </svg>
            </button>
            <p className="backToCartText">OFFERS</p>
          </div>

          {couponData &&
            couponData.map((coupon) => {
              return (
                <div className="couponsContainer">
                  <div className="couponContainer">
                    <div class="leftHalfCircle"></div>
                    <div class="rightHalfCircle"></div>
                    <div>
                      <svg
                        width="34"
                        height="32"
                        viewBox="0 0 34 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.481404 19.5325C0.104572 19.5629 -0.0479843 18.9794 0.296077 18.8228C10.5007 14.176 12.9613 8.97187 11.9441 -1.95706C11.9102 -2.32127 12.4384 -2.47682 12.6072 -2.15232C17.6991 7.63649 22.7456 10.9423 33.0659 10.0385C33.4411 10.0057 33.5994 10.5831 33.2592 10.7446C24.1395 15.0754 20.6282 19.7592 21.5658 31.6196C21.5943 31.9805 21.0756 32.132 20.9072 31.8115C15.4763 21.4723 10.7823 18.7027 0.481404 19.5325Z"
                          fill="url(#paint0_linear_626_183)"
                          fill-opacity="0.5"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_626_183"
                            x1="10.1213"
                            y1="26.7327"
                            x2="20.3029"
                            y2="1.65559"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#C678F4" />
                            <stop offset="0.46875" stop-color="#FF55BC" />
                            <stop offset="0.854229" stop-color="#FF8210" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div className="couponInner">
                      {/* <img src="/coupon.png" alt="" /> */}
                      <div className="couponContent">
                        <p className="couponCode">{coupon?.discount_code}</p>
                        <p className="couponDescription">{coupon?.name}</p>
                        {/* <p className="couponDetails">{coupon?.name}</p> */}
                      </div>
                    </div>
                    <div className="ApplyCouponContainer">
                      <button
                        className="applyCouponButton"
                        onClick={() =>
                          applyCouponCodeHandle(coupon?.discount_code)
                        }
                      >
                        APPLY
                      </button>

                      <div className="couponStarFirst">
                        <svg
                          width="34"
                          height="32"
                          viewBox="0 0 34 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.481404 19.5325C0.104572 19.5629 -0.0479843 18.9794 0.296077 18.8228C10.5007 14.176 12.9613 8.97187 11.9441 -1.95706C11.9102 -2.32127 12.4384 -2.47682 12.6072 -2.15232C17.6991 7.63649 22.7456 10.9423 33.0659 10.0385C33.4411 10.0057 33.5994 10.5831 33.2592 10.7446C24.1395 15.0754 20.6282 19.7592 21.5658 31.6196C21.5943 31.9805 21.0756 32.132 20.9072 31.8115C15.4763 21.4723 10.7823 18.7027 0.481404 19.5325Z"
                            fill="url(#paint0_linear_626_183)"
                            fill-opacity="0.5"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_626_183"
                              x1="10.1213"
                              y1="26.7327"
                              x2="20.3029"
                              y2="1.65559"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#C678F4" />
                              <stop offset="0.46875" stop-color="#FF55BC" />
                              <stop offset="0.854229" stop-color="#FF8210" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>

                      <div className="couponStarThird">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 34 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.481404 19.5325C0.104572 19.5629 -0.0479843 18.9794 0.296077 18.8228C10.5007 14.176 12.9613 8.97187 11.9441 -1.95706C11.9102 -2.32127 12.4384 -2.47682 12.6072 -2.15232C17.6991 7.63649 22.7456 10.9423 33.0659 10.0385C33.4411 10.0057 33.5994 10.5831 33.2592 10.7446C24.1395 15.0754 20.6282 19.7592 21.5658 31.6196C21.5943 31.9805 21.0756 32.132 20.9072 31.8115C15.4763 21.4723 10.7823 18.7027 0.481404 19.5325Z"
                            fill="url(#paint0_linear_626_183)"
                            fill-opacity="0.5"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_626_183"
                              x1="10.1213"
                              y1="26.7327"
                              x2="20.3029"
                              y2="1.65559"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#C678F4" />
                              <stop offset="0.46875" stop-color="#FF55BC" />
                              <stop offset="0.854229" stop-color="#FF8210" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>

                      <div className="couponStarSecond">
                        <svg
                          width="34"
                          height="32"
                          viewBox="0 0 34 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.481404 19.5325C0.104572 19.5629 -0.0479843 18.9794 0.296077 18.8228C10.5007 14.176 12.9613 8.97187 11.9441 -1.95706C11.9102 -2.32127 12.4384 -2.47682 12.6072 -2.15232C17.6991 7.63649 22.7456 10.9423 33.0659 10.0385C33.4411 10.0057 33.5994 10.5831 33.2592 10.7446C24.1395 15.0754 20.6282 19.7592 21.5658 31.6196C21.5943 31.9805 21.0756 32.132 20.9072 31.8115C15.4763 21.4723 10.7823 18.7027 0.481404 19.5325Z"
                            fill="url(#paint0_linear_626_183)"
                            fill-opacity="0.5"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_626_183"
                              x1="10.1213"
                              y1="26.7327"
                              x2="20.3029"
                              y2="1.65559"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#C678F4" />
                              <stop offset="0.46875" stop-color="#FF55BC" />
                              <stop offset="0.854229" stop-color="#FF8210" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      ) : null}
    </>
  );
}
