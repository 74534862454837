import { useState } from "react";
import "./App.css";
import RoutesPage from "./routes";
import "./styles/bootstrap.css";
import "./styles/filter.css";


function App() {
  const [overLay, setoverLay] = useState(false);
  const isOverlay = (data) => {
    setoverLay(data);
  }

  return (
    <div className="App">
      <div className={`overlay ${overLay === true ? 'overWrapper' : 'no'}`}></div>
      <RoutesPage isOverlay={isOverlay} />
     
    </div>
  );
}

export default App;
