import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "../styles/header.css";
import logo from "../logo.svg";
import { AppContext } from "../context/appContext";

import Cart from "./cart.js";

const Header = ({
  isOverlay,
  atcTrue,
  // counts,
  setPoputrue,
  cartCounts,
  cartData,
  cartDataApi,
  windowWidth,
}) => {
  // const { counts, WishlistApi, cleverTapEvent } = useContext(AppContext);
  const { counts, WishlistApi, redirect, setRedirect, cleverTapEvent } =
    useContext(AppContext);

  const [Open, setOpen] = useState(false);
  const [OpenDrawer, setOpenDrawer] = useState(false);
  const [OpenAccount, setOpenAccount] = useState(false);
  const [sticky, setSticky] = useState(false);
  // const [cartData, setcartData] = useState([]);
  // const [cartCounts, setCartCounts] = useState(0);
  // const [userId, setUserId] = useState('e2df1805-a82e-42e9-8ed7-1888b5964900');
  // const [wishlistData,setWishlistData] = useState([]);
  var userId = localStorage.getItem("userID");
  let found = false;

  console.warn("=====> cartCounts ===>", cartCounts);
  // const cartDataApi = async () => {
  //   if (userId) {
  //     const CartRes = await fetch(
  //       `https://foyforyou.com/foyapi/api/customer/ManageCart/GetCartItemByUser?userId=${userId}`
  //     );
  //     const CartResData = await CartRes.json();
  //     setcartData(CartResData.data);
  //     let cartCount = 0;
  //     const cartDataLength = CartResData.data?.length;
  //     console.log("cartDataLength>>", cartDataLength);
  //     if (cartDataLength === 0) {
  //       setCartCounts(cartCount);
  //       // handleShopping();
  //     }
  //     CartResData.data?.forEach((item) => {
  //       cartCount = cartCount + 1;
  //       setCartCounts(cartCount);
  //     });

  //     console.log("cartCount>>", cartCount);
  //     // if(cartCount === 0){
  //     //   handleShopping();
  //     //   console.log("handleeee")
  //     // }
  //   }
  // };

  //  const WishlistApi = async () =>{
  //   if(userId){
  //   const WishlistRes = await fetch(
  //     `https://foyforyou.com/foyapi/api/customer/ManageCart/GetWishListItemByUser?userId=${userId}`
  //   );
  //   const WishlistData = await WishlistRes.json();
  //   setWishlistData(WishlistData.data);
  //   console.log()
  //   WishlistData.data.forEach((item)=>{
  //     if(item.productdetail.id === variantId){
  //       console.log("hello");
  //       found = true;
  //       localStorage.setItem('wishlistfound',found);
  //       localStorage.setItem('wishlistId',item.id);
  //     }

  //   })
  //   if(!found){
  //     localStorage.setItem('wishlistfound',found);
  //     localStorage.setItem('wishlistId',' ');
  //   }
  //   }
  //  }
  // cartDataApi();
  // const fetchCartData = async () => {
  //   try {
  //     const response = await axios.get(`https://foyforyou.com/foyapi/api/customer/ManageCart/GetCartItemByUser?userId=${userId}`);
  //     setcartData(response.data.data);
  //   } catch (error) {
  //     console.error('Error fetching cart data:', error);
  //   }
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     cartDataApi();
  //   }, 1e3);
  // }, [atcTrue]);

  // useEffect(() => {
  //   WishlistApi();
  // }, []);

  const wishlistRedirect = () => {
    cleverTapEvent(undefined, "VTO_Wishlist_Clicked");
    if(userId == null){
      userId = localStorage.getItem('guestId');
    }
    
    setTimeout(() => {
      window.location.href = `https://foyforyou.com/product-list/wishlist?UserId=${userId}`;
    }, 1000);
  };
  const openMenu = () => {
    if (Open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleShopping = () => {
    cleverTapEvent(undefined, "VTO_Cart_Clicked");
    var userId = localStorage.getItem("userID");
    // console.log("userId", userId);
    if (userId === null) {
      setPoputrue(true);
      setRedirect("cart");
    } else {
      setTimeout(() => {
        window.location.href = `https://foyforyou.com/checkout-new-mob/cart?UserId=${userId}`;
      }, 1000);
    }
    // if (OpenDrawer === true) {
    //   setOpenDrawer(false);
    //   isOverlay(false);
    // } else {
    //   // cartDataApi();
    //   setOpenDrawer(true);
    //   isOverlay(true);
    // }
    // cartDataApi();
  };
  const handleAccount = () => {
    if (OpenAccount === true) {
      setOpenAccount(false);
    } else {
      setOpenAccount(true);
    }
  };

  window.addEventListener("scroll", function (e) {
    let scrollTop = this.pageYOffset;
    if (scrollTop > 0) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  });

  return (
    <div className={`header ${windowWidth <= 768 && sticky === true ? "sticky" : ""}`}>
      <div className="headerComponents container">
        <div className="headerOuter">
          {/* <div
            className={`humgbugger_menu ${Open === true ? "hamburger" : ""}`}
          >
            <div onClick={openMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div> */}
          <div className="headerLogoMenu">
            <div>
              <img className="deskLogo" src={logo} alt="Logo" title="Logo" />
              <img className="mobLogo" src="/mob.png" alt="Logo" title="Logo" />
            </div>
          </div>
          {/* <div className="headerMenu">
            <ul className="desktop_menu">
              <li>
                <Link className="link" to="/brands">
                  Shop All
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Today's offer
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  FAOR TV
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  BLOGS
                </Link>
              </li>
            </ul>
          </div> */}

          <div className="social_outer">
            <div className="menuActions">
              <div className="menuAction search_tab">
                <input type="text" />
              </div>
              <div className="menuAction profile_tab" onClick={handleAccount}>
                <img src="/profile.svg" alt="profile" title="profile" />
              </div>
              <div className="menuAction wishlist" onClick={wishlistRedirect}>
                <img src="/wishlist.svg" alt="Wishlist" title="Wishlist" />
                <span className="wishlistCounts">{counts}</span>
              </div>
              <div className="menuAction cartIcon" onClick={handleShopping}>
                <img
                  src="/shopping-cart.svg"
                  alt="Shopping Cart"
                  title="Shopping Cart"
                />
                <span className="cart_counts">{cartCounts}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`cartDrawer ${OpenDrawer === true ? "cartOpen" : ""}`}>
        <div className="Drawerbody">
          <p
            onClick={handleShopping}
            className={`cross_icon ${OpenDrawer === false ? "cartClose" : ""}`}
          >
            <svg
              className="Icon Icon--close "
              role="presentation"
              viewBox="0 0 16 14"
            >
              <path
                d="M15 0L1 14m14 0L1 0"
                stroke="currentColor"
                fill="none"
                fillRule="evenodd"
              ></path>
            </svg>
          </p>
          <Cart cartData={cartData} cartDataApi={cartDataApi} />
        </div>
      </div>
      <div
        className={`menu_cart ${Open === true ? "addMenu" : ""} ${
          Open === false ? "closeMenu" : ""
        }`}
      >
        <div className="cart_body">
          <div className="mobile_menu">
            <div className="profile_wrapper">
              <h4>PROFILE</h4>
              <div className="profile_image">
                <img src="/profile.png" alt="Profile" title="Profile" />
              </div>
              <p>Profile and Account</p>
            </div>
            <ul>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
              <li>
                <Link className="link" to="/brands">
                  Brands
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`popWrapper ${OpenAccount === true ? "openPop" : ""}`}>
        <div className="popInner">
          <div className="LoginForm">
            <div className="logoLogin">
              <div className="img_cart">
                <img src="/bg-logo.svg" alt="BG Logo" title="BG Logo" />
              </div>
              <div className="img_cancel" onClick={handleAccount}>
                <img src="/cancel-icon.svg" alt="Cancel" title="Cancel" />
              </div>
            </div>
            <h5>LOGIN OR SIGNUP</h5>
            <div className="number_text">
              Enter your phone number to verify yourself
            </div>
            <div className="input-box-detail">
              <div className="code-input">
                <input type="text" disabled value="+91" />
              </div>
              <div className="number_input">
                <input type="tel" placeholder="Mobile number" />
              </div>
            </div>
            <div className="submitBtn">
              <button type="button">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;