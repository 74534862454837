import React, { useEffect, useState } from 'react'

export default function Concern({pass_FiltercheckBox}) {
    const [filterConcernData, setfilterConcernData] = useState([])
    const ConcernApi = async () =>{
        const ConcernRes = await fetch("https://foyforyou.com/foyapi/api/Category/Get_SubCategory_SkinType_Concern_ByCategoryId")
        const ConcernResData = await ConcernRes.json();
        // console.log(ConcernResData,"ConcernResData>>>>>")
        setfilterConcernData(ConcernResData.data.skinConcernList)
    }
    useEffect(()=>{
        ConcernApi()
    },[])
    return (
        <div>
        <div className='filter_item font-Harmonia'>
            {
                filterConcernData.map((Concern, key) => {
                    return (
                    <div className="filterTitle" key={key}>
                        <label className="label_Brand">
                        <input
                            type="checkbox"
                            id="brand"
                            name="skin_concerns"
                            value={Concern?.id}
                            onChange={(e) => {
                            pass_FiltercheckBox(e);
                            }}
                        />
                        {Concern?.name}
                        <div className="common-checkboxIndicator"></div>
                        </label>
                    </div>
                    )
                })
                }
        </div>
        </div>
    )
}