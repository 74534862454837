import React, { useEffect, useState, useContext } from "react";
import "../styles/filter.css";
import Brands from "../components/Brands";
import Price from "../components/Price";
import Categories from "../components/Categories";
// import Color from "../components/Color";
import Type from "../components/Type";
import Gender from "../components/Gender";
import Skin from "../components/Skintype";
import Concern from "../components/Concern";
import Leaf from "../components/Leaf";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/appContext";

export default function Filter(data) {
  // const [DispalyActive, SetDispalyActive] = useState(true);
  const { wishlistDataSet, WishlistApi, cleverTapEvent } =
    useContext(AppContext);

  const [ActiveFilter, SetActiveFilter] = useState("brands");
  const [selectedFilters, setSelectedFilters] = useState({
    skip: 0,
    take: 18,
    categoryIds: "5",
  });
  const [ApplyFilters, setApplyFilters] = useState(false);
  const [loading, setloading] = useState(false);
  const [filterProducts, setfilterProducts] = useState("");
  const [disable, setdisable] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [filterValue, setFilterValue] = useState(null);
  const [filterchange, setFilterchange] = useState(false);
  const [leafData, setLeafData] = useState([]);
  const [leafvalue, setLeafvalue] = useState([]);
  const [logPop, setLogPop] = useState(false);
  const [BrandName, setBrandName] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [variantPrice, setVariantPrice] = useState();
  // const [filterName, setFilterName] = useState();
  // const [productAdded, setproductAdded] = useState([]);
  const navigate = useNavigate();

  const HandleClose = () => {
    data.cleverTapEvent(undefined, "VTO_Filter_Close_clicked");
    data.sendToParent(false);
    // setloading();
    // localStorage.setItem("selectedVal"," ")
  };
  useEffect(() => {
    if (data.staticFilter === true) {
      setFilterText("Shop Now");
      // alert();
      setApplyFilters(true);
      setfilterProducts(data.filterdata);
    } else {
      setFilterText(null);
      setApplyFilters(ApplyFilters);
      setfilterProducts(filterProducts);
    }
  }, []);

  const addtoBag = (e, wishlist, id) => {
    cleverTapEvent(undefined, "VTO_Add_to_Wishlist_Clickedx");
    var button = e.target
      .closest("button")
      ?.querySelector(".ATC_button_action");
    var loader = e.target.closest("button")?.querySelector(".productLoader");
    const userID = localStorage.getItem("userID");
    if (userID) {
      setLogPop(true);
      if (button && loader) {
        button.classList.add("hidden");
        loader.classList.remove("hidden");
      }
      setdisable(true);

      const isExistInWishlist = wishlistDataSet?.find(
        (item) => item.product_id === id
      );
      console.log("ooooooooooooooooooooooooo", id, isExistInWishlist);
      // const payload = {
      //   product_id: id,
      //   user_id: ,
      //   qty: 1,
      //   iswishlistitem: wishlist,
      // };

      const payload = {
        user_id: userID,
        product_id: id,
        iswishlistitem: wishlist,
        qty: isExistInWishlist?.product_id === id ? 0 : 1,
        ...(isExistInWishlist?.product_id === id && {
          id: isExistInWishlist.id,
        }),
      };
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://foyforyou.com/foyapi/api/customer/ManageCart/ManageCartByUser",
        headers: {
          "Content-Type": "application/json",
        },
        data: payload,
      };
      axios
        .request(config)
        .then((response) => {
          // run api wishlist
          WishlistApi();
          setdisable(false);
          if (button && loader) {
            button.classList.remove("hidden");
            loader.classList.add("hidden");
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
          setdisable(false);
          if (button && loader) {
            button.classList.remove("hidden");
            loader.classList.add("hidden");
          }
        });
    } else {
      data.setPoputrue(true);
      console.log(" setPoputrue>>", data.setPoputrue);
      // addtoBag(e, id, wishlist);
      // setLogPop(false)
    }
  };

  // leaf filter apis
  const LeafApi = async () => {
    var LeafRes, LeafResData;

    var leafDatanew = [
      { id: 17, title: "Lipstick" },
      { id: 10104, title: "Liquid Lipstick" },
      { id: 10105, title: "Lip Crayon" },
      { id: 16, title: "Lip Gloss" },
      { id: 10106, title: "Lip Liner" },
      { id: 10039, title: "Lip Tint" },
      { id: 10040, title: "Lip Plumper" },

      { id: 10101, title: "Eye Brow Enhancers" },
      { id: 10099, title: "Eyeliner" },
      { id: 10071, title: "Eyeshadow" },

      { id: 10019, title: "Compact" },
      { id: 10098, title: "Kajal" },
      { id: 10100, title: "Mascara" },

      { id: 12, title: "Concealer" },
      { id: 14, title: "Foundation" },
      { id: 10023, title: "Blush" },
      { id: 10028, title: "Highlighter" },
      { id: 10096, title: "Bronzer" },
      { id: 10020, title: "Contour" },
    ];

    if (
      leafvalue === undefined ||
      leafvalue === null ||
      leafvalue.length === 0
    ) {
      setLeafData(leafDatanew);
      LeafResData = leafData;
    } else {
      LeafRes = await fetch(
        `https://foyforyou.com/foyapi/api/leaf_level_category/GetAllLeafCategory?subcategoryids=${leafvalue}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      LeafResData = await LeafRes.json();
      setLeafData(LeafResData.data);
    }

    // console.log(LeafRes, "LeafRes>>>>>>>>>")

    console.log("leafvalue", leafvalue);
  };
  // leaf filter apis ends
  // console.log("LeafResData>>>", leafData);
  // all filter apis data
  const CategoryApi = async () => {
    var CategoryRes;
    // console.log("FilterValue>>>",filterValue);

    CategoryRes = await fetch(
      `https://foyforyou.com/foyapi/api/Category/Get_SubCategory_SkinType_Concern_ByCategoryId?categoryIds=5`
    );
    const CategoryResData = await CategoryRes.json();
    // console.log(CategoryResData,"CategoryResData>>>>>")
    setCategoryData(CategoryResData.data.subCategoryList);
  };

  // all filter apis data code ends

  // brands APi data
  // brands API

  useEffect(() => {
    const storedFilterData = sessionStorage.getItem("selectedFilters");
    if (storedFilterData) {
      try {
        const parsedData = JSON.parse(storedFilterData);
        setSelectedFilters(parsedData);
      } catch (error) {
        console.error("Error parsing stored filter data:", error);
        // Handle the error, e.g., clear the invalid data
        sessionStorage.removeItem("selectedFilters");
      }
    }

  }, []);

  const FiltercheckBox = (e) => {
    cleverTapEvent(undefined, "VTO_L2_clicked");
    var boxname = e.target.getAttribute("inputkey");
    console.log("e.target.name>>", e.target.name);
    // setFilterName(e.target.name);
    var checkedBrand = e.target.checked;
    if (e.target.name == "brand") {
      setFilterText(null);
      if (checkedBrand) {
        setBrandName([...BrandName, boxname]);
        console.log("BrandName", BrandName, boxname);
      } else {
        setBrandName(BrandName.filter((item) => item !== boxname));
      }
    } else {
      setFilterText("Shop Now");
    }
    const { value, checked, name } = e.target;


    setSelectedFilters((prev) => {
      const updatedFilters = { ...prev };
      if (checked) {
        if (e.target.name === "categoryIds") {
          // console.log("events>>>",e.target.name)

          // }
          // setFilterchange(true);
          if (filterValue === null) {
            setFilterValue(e.target.value);
            console.log("filterValue 236", e.target.value);
            console.log("filterValue 237", filterValue);
          } else {
            setFilterValue(filterValue + "," + e.target.value);
            console.log("filterValue 238", filterValue, e.target.value);
          }
        }

        if (!updatedFilters.hasOwnProperty(name)) {
          updatedFilters[name] = value;
          console.log("upadtenameValue", name, " value ", value);
        } else {
          updatedFilters[name] += "," + value;
        }
        console.log("updatedFilters", updatedFilters);

        if (name === "sub_category_id") {
          console.log(
            "leafvalue>>>",
            leafvalue,
            "e.target.value",
            e.target.value
          );
          if (leafvalue === null) {
            console.log("!");
            setLeafvalue(e.target.value);
          } else {
            console.log("@");
            if (!leafvalue.includes(e.target.value)) {
              setLeafvalue((prev) => [...prev, e.target.value]);
              // setLeafvalue(e.target.value);
              // setLeafvalue([...leafvalue,e.target.value]);
            }
          }

          // updatedFilters[catValue] = value;
        }
      } else {
        console.log("notChecked");
        if (e.target.name === "categoryIds") {
          const updatedValues = filterValue.filter(
            (value) => value !== e.target.value
          );
          setFilterValue(updatedValues);
        }

        if (name === "sub_category_id") {
          const updatedValues = leafvalue.filter(
            (value) => value !== e.target.value
          );
          console.log("updatedValues>>>", updatedValues);
          // console.log("leafvalueleafvalueleafvalueleafvalueleafvalue");
          setLeafvalue(updatedValues);
        }
       
        if (updatedFilters.hasOwnProperty(name)) {
          updatedFilters[name] = updatedFilters[name].replace(
            new RegExp(`,?\\s?${value}(,\\s?)?`),
            ""
          );
          if (updatedFilters[name].trim() === "") {
            delete updatedFilters[name];
          }
          // if(catValue){
          //   if (updatedFilters[catValue].trim() === '') {
          //     delete updatedFilters[catValue];
          //   }
          // }
        }
      }
      return updatedFilters;
    });
  };
  console.log("BrandName", BrandName);

  const HandleFilterApply = () => {
    data.cleverTapEvent(undefined, "VTO_Filter_Apply_clicked");
    setApplyFilters(true);
    fetchFilterProductTarget();

    const isDataFromFilter = {
      isFromFilter: true,
      data: data.product,
      // filterData: finalFilterData,
    };
    // console.log(selectedFilters,"selectedFilters>>>>>>>>>>>>>")
    // console.log(finalFilterData,"finalFilterData>>")
  };
  const Filterchnage = (e) => {
    // console.log("ffff");
    // setFilterchange(true);
    // setFilterValue(e.target.value);
    const clickedText = e.target.textContent;
    SetActiveFilter(clickedText);

    // if (clickedText === "brands" || clickedText === "price") {
    //   SetDispalyActive(true);
    // } else {
    //   SetDispalyActive(false);
    // }
  };

  console.log("selectedFilters 325", selectedFilters);

  // useEffect(()=>{
  //   console.log("Brandname",BrandName.length)
  // },[BrandName])
  console.log("filterValue", filterValue);

  const fetchFilterProductTarget = () => {
    // console.log("fetch");
    setloading(true);
    sessionStorage.setItem("selectedFilters", JSON.stringify(selectedFilters));
    let payload = JSON.stringify(selectedFilters);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://foyforyou.com/foyapi/api/customer/Product/fetchProductByParams",
      // url: "https://foyforyou.com/foyapi/api/customer/Product/GetProductByParameters",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response?.data),"nmnnnnnnnnnnn");
        console.log(response, "response>>>>>>>>>>>>");
        setfilterProducts(response?.data.data);
        setloading(false);
      })
      .catch((error) => {
        console.log(error);
        setloading(false);
      });
  };

  const tryon = (e, id) => {
    // data.fetchData(id)

    cleverTapEvent(undefined, "VTO_L3_clicked");
    data.setProduct(id);
    let user = localStorage.getItem("userId");
    let url = user
      ? `/?product=${id}&userId=${user}`
      : // : `/?product=${id}&ymk=true`;
        `/?product=${id}`;

    navigate(url);
    HandleClose();
    window.location.reload();
  };

  useEffect(() => {
    CategoryApi();
  }, [filterValue]);
  useEffect(() => {
    LeafApi();
  }, [leafvalue]);
  // console.log("data.product", data.product);
  return (
    <>
      <div
        className={`filterWrapper ${data?.prop === true ? "filterOpen" : ""}`}
      >
        <div className="filterOuter">
          <button className="crossbtn" id="crossBtn" onClick={HandleClose}>
            <svg
              className={`Icon Icon--close`}
              role="presentation"
              viewBox="0 0 16 14"
            >
              <path
                d="M15 0L1 14m14 0L1 0"
                stroke="currentColor"
                fill="none"
                fillRule="evenodd"
              ></path>
            </svg>
          </button>
          <div className="filterInner">
            {ApplyFilters === false ? (
              <div
                className={`searchFilter ${
                  ApplyFilters === false ? "searchActive" : ""
                }`}
              >
                <div className="searchFilters">
                  <h2 className="font-Harmonia">FILTER</h2>
                </div>
                <div className="Filters-Content">
                  <div className="filter_left">
                    <ul className="filtersPane font-Harmonia">
                      <li
                        className={`ripple-container ${
                          ActiveFilter === "brands" ? "checkInput" : ""
                        }`}
                        onClick={(e) => {
                          Filterchnage(e);
                        }}
                      >
                        <label>brands</label>
                      </li>
                      <li
                        className={`ripple-container ${
                          ActiveFilter === "price" ? "checkInput" : ""
                        }`}
                        onClick={(e) => {
                          Filterchnage(e);
                        }}
                      >
                        <label>price</label>
                      </li>
                      <li
                        className={`ripple-container ${
                          ActiveFilter === "Category" ? "checkInput" : ""
                        }`}
                        onClick={(e) => {
                          Filterchnage(e);
                        }}
                      >
                        <label>Category</label>
                      </li>
                      {/* <li
                        className={`ripple-container ${
                          ActiveFilter === "category" ? "checkInput" : ""
                        }`}
                        onClick={(e) => {
                          Filterchnage(e);
                        }}
                      >
                        <label>category</label>
                      </li> */}
                      {/* <li
                        className={`ripple-container ${
                          ActiveFilter === "color" ? "checkInput" : ""
                        }`}
                        onClick={(e) => {
                          Filterchnage(e);
                        }}
                      >
                        <label>color</label>
                      </li> */}
                      {/* <li
                        className={`ripple-container ${
                          ActiveFilter === "gender" ? "checkInput" : ""
                        }`}
                        onClick={(e) => {
                          Filterchnage(e);
                        }}
                      >
                        <label>gender</label>
                      </li> */}
                      {/* <li
                        className={`ripple-container ${
                          ActiveFilter === "skin type" ? "checkInput" : ""
                        }`}
                        onClick={(e) => {
                          Filterchnage(e);
                        }}
                      >
                        <label>skin type</label>
                      </li> */}
                      {/* <li
                        className={`ripple-container ${
                          ActiveFilter === "concern" ? "checkInput" : ""
                        }`}
                        onClick={(e) => {
                          Filterchnage(e);
                        }}
                      >
                        <label>concern</label>
                      </li> */}

                      <li
                        className={`ripple-container ${
                          ActiveFilter === "Sub-Category" ? "checkInput" : ""
                        }`}
                        onClick={(e) => {
                          Filterchnage(e);
                        }}
                      >
                        <label>Sub-Category</label>
                      </li>
                    </ul>
                  </div>
                  <div className="divider_line"></div>
                  <div className="filter_right">
                    <div
                      className={`filter_option ${
                        ActiveFilter === "brands" ? "filterActive" : ""
                      }`}
                    >
                      <Brands
                        pass_FiltercheckBox={FiltercheckBox}
                        selectedProduct={data.product}
                        brandValues={selectedFilters?.brand}
                      />
                    </div>
                    <div
                      className={`filter_option ${
                        ActiveFilter === "price" ? "filterActive" : ""
                      }`}
                    >
                      <Price
                        pass_FiltercheckBox={FiltercheckBox}
                        priceValues={selectedFilters?.pricerange}
                      />
                    </div>
                    <div
                      className={`filter_option ${
                        ActiveFilter === "Category" ? "filterActive" : ""
                      }`}
                    >
                      <Categories
                        pass_FiltercheckBox={FiltercheckBox}
                        categoryData={categoryData}
                        selectedProduct={data.product}
                        leafvalue={selectedFilters?.sub_category_id}
                      />
                    </div>
                    <div
                      className={`filter_option ${
                        ActiveFilter === "category" ? "filterActive" : ""
                      }`}
                    >
                      <Type pass_FiltercheckBox={FiltercheckBox} />
                    </div>
                    {/* <div
                      className={`filter_option ${
                        ActiveFilter === "color" ? "filterActive" : ""
                      }`}
                    >
                      {" "}
                      <Color pass_FiltercheckBox={FiltercheckBox} />
                    </div> */}
                    <div
                      className={`filter_option ${
                        ActiveFilter === "gender" ? "filterActive" : ""
                      }`}
                    >
                      <Gender pass_FiltercheckBox={FiltercheckBox} />
                    </div>
                    <div
                      className={`filter_option ${
                        ActiveFilter === "skin type" ? "filterActive" : ""
                      }`}
                    >
                      <Skin pass_FiltercheckBox={FiltercheckBox} />
                    </div>
                    <div
                      className={`filter_option ${
                        ActiveFilter === "concern" ? "filterActive" : ""
                      }`}
                    >
                      <Concern pass_FiltercheckBox={FiltercheckBox} />
                    </div>
                    <div
                      className={`filter_option ${
                        ActiveFilter === "Sub-Category" ? "filterActive" : ""
                      }`}
                    >
                      <Leaf
                        pass_FiltercheckBox={FiltercheckBox}
                        filterLeafData={leafData}
                        selectedProduct={data.product}
                        leaflevelValues={
                          selectedFilters?.leaf_level_category_Ids
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`ResultFilter ${
                  ApplyFilters === true ? "resultActive" : ""
                }`}
                onClick={(e) => {
                  // HandleFilterApply(e);
                }}
              >
                <div className="searchFilters">
                  <h2 className="font-Harmonia">
                    {filterText
                      ? filterText
                      : `${BrandName && 
                          BrandName.length > 1
                            ? "Shop From Brands"
                            : `Shop From ${BrandName[0] !== undefined ? BrandName[0] : 'Selection'}`
                        }`}
                  </h2>
                </div>

                {loading === true ? (
                  <>
                    <div className="loaderContainer">
                      <img className="loader" src="/loader.svg" alt="loader" />
                    </div>
                  </>
                ) : (
                  <div>
                    {filterProducts.length < 1 ? (
                      <div className="card_notFound">No data found</div>
                    ) : (
                      <div className="card_wrapper">
                        {filterProducts &&
                          filterProducts.map((product, key) => {
                            let discountList =
                              product?.extras[0]?.color_list[0]?.discountList;
                            var percentageDiff =
                              parseInt(discountList[0]?.percentage) || 0;
                            var variantPrices = 0;

                            console.log(
                              "product.list_price>>>>",
                              typeof product.list_price
                            );
                            if (percentageDiff) {
                              console.log("percentageDiff>>>", percentageDiff);
                              var variantPrice2 =
                                (product.list_price * percentageDiff) / 100;
                              variantPrices = (
                                product.list_price - variantPrice2
                              ).toFixed();
                              // setVariantDiscount(percentageDiff);
                              // setVariantPrice(variantPrices);
                              console.log("variantPrice>>>>", variantPrice);
                            }
                            const isAdded = wishlistDataSet.some(
                              (data) => data.product_id === product.id
                            );
                            // console.log(
                            //   "isAddedpppppppppppppp",
                            //   product.id,
                            //   isAdded
                            // );
                            // const isAdded = false;
                            return (
                              console.log( product?.extras[0]?.color_list," product?.extras[0]?.color_list"),
                              product?.extras[0]?.color_list &&
                              product?.extras[0]?.color_list.length > 0 &&
                              product?.stock > 0 &&
                              product?.extras[0]?.color_list.some(
                                (color) => color.color !== "" && color.color !== "Multi" && color.color !== null
                              ) && (
                                <div className="cardContainer" key={key}>
                                  <div className="upperContent">
                                    <p className="m0 cardTitle font-RedHat">
                                      {product?.brand?.name}
                                    </p>
                                    <div>
                                      <img
                                        className="productImg"
                                        src={product?.imageList[0]?.path}
                                        width={150}
                                        height={150}
                                        alt={product?.name}
                                        title={product?.name}
                                      />
                                    </div>
                                  </div>
                                  {/*  */}
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: "2px 10px",
                                        borderRadius: "3px",
                                        fontSize: "9px",
                                        fontWeight: "600",
                                        // lineHeight: "12px",
                                        color: "#000",
                                        // background: item?.extras[0]?.color_list[0]?.color,
                                        background: "#EBEBEB",
                                      }}
                                    >
                                      {product?.leafCategories[0]?.title}
                                    </div>
                                  </div>
                                  <div className="middleContent">
                                    <div className="productDetail">
                                      <p className="m0 productName font-Pretendard ">
                                        {product?.name}
                                      </p>
                                      <p className="m0 productSize font-RedHat">{`(${product?.extras[0]?.size})`}</p>
                                    </div>
                                    <div className="productPrice">
                                      <p className="actualPrice m0 font-RedHat">
                                        {variantPrices == 0
                                          ? ""
                                          : "₹" + variantPrices}
                                      </p>
                                      {product?.list_price === 0 ? (
                                        ""
                                      ) : (
                                        <>
                                          <p
                                            className={
                                              percentageDiff === 0
                                                ? " actualPrice m0 font-RedHat"
                                                : "comparePrice  m0 font-RedHat"
                                            }
                                          >
                                            ₹{product?.list_price}
                                          </p>
                                        </>
                                      )}

                                      <p className="discount m0 font-RedHat">
                                        {percentageDiff === 0
                                          ? ""
                                          : percentageDiff + "%"}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="shades m0 font-RedHat">
                                    {" "}
                                    {product?.extras.length > 0 ? (
                                      <>{`${product?.extras[0]?.color_list.length} Shades`}</>
                                    ) : null}
                                  </p>
                                  <div className="bottomContent">
                                    <button
                                      className="favBtn"
                                      type="button"
                                      onClick={(e) => {
                                        addtoBag(e, !isAdded, product?.id);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 30 30"
                                        fill={
                                          isAdded === true ? "#FF55BC" : "none"
                                        }
                                      >
                                        <path
                                          d="M5.56335 17.3853L14.3153 25.6068C14.6398 25.9116 14.802 26.064 15 26.064C15.198 26.064 15.3602 25.9116 15.6847 25.6068L24.4367 17.3853C26.8819 15.0882 27.1788 11.3082 25.1223 8.65758L24.7356 8.15918C22.2753 4.98823 17.337 5.52002 15.6083 9.14206C15.3641 9.6537 14.6359 9.6537 14.3917 9.14206C12.663 5.52002 7.72465 4.98823 5.26443 8.15918L4.87773 8.65759C2.82118 11.3083 3.11813 15.0882 5.56335 17.3853Z"
                                          stroke="#FF55BC"
                                          strokeWidth="1.5"
                                        />
                                      </svg>
                                    </button>
                                    <div className="atcBtns_wrraper">
                                      <button
                                        className="atcAction"
                                        disbaled={
                                          disable === true ? `true` : `false`
                                        }
                                        onClick={(e) => {
                                          // tryon(e, product?.id);
                                          data.fetchData(product?.id);
                                        }}
                                      >
                                        <span className="ATC_button_action font-Harmonia">
                                          {/* <span className="ATC_button">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 20 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M11.1092 14.1667V5.83334"
                                          stroke="white"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          d="M7.18068 10L15.0378 10"
                                          stroke="white"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                      </svg>
                                      </span>{" "} */}
                                          <span>TRY IT ON</span>
                                        </span>
                                        <div className="productLoader hidden"></div>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )
                            );
                          })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {ApplyFilters === false ? (
              <div className="footerFilter">
                <div
                  className={`close_btn  ${
                    data?.prop === false ? "filterOpen" : ""
                  }`}
                >
                  <button
                    className="font-RedHat font-filter"
                    type="button"
                    onClick={() => {
                      HandleClose();
                    }}
                  >
                    Close
                  </button>
                </div>
                <div className="footerApply ">
                  <button
                    className="font-RedHat font-filter"
                    type="button"
                    onClick={(e) => {
                      HandleFilterApply(e);
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
