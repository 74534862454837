import React, { useEffect, useState, useRef } from "react";
import "../styles/recommendation.css";
import { useNavigate } from "react-router-dom";

const Productrecommendations = ({
  handleAddToCart,
  wishlistDataSet,
  cleverTapEvent,
  showSimilarProducts,
  setShowSimilarProducts,
  handlers,
  upHandler,
  fetchData,
}) => {
  const [productData, setProductData] = useState([]);

  const categoryId = localStorage.getItem("categoryId");
  const brandId = localStorage.getItem("brandId");
  const subCatId = localStorage.getItem("subCatId");
  const navigate = useNavigate();
  const recommendedProducts = async (categoryId, brandId, subCatId) => {
    const payloadBrand = {
      categoryIds: categoryId === "undefined" ? "" : categoryId,
      brand: brandId === "undefined" ? "" : brandId,
      sub_category_id: subCatId === "undefined" ? "" : subCatId,
    };
    const brandRes = await fetch(
      "https://foyforyou.com/foyapi/api/customer/Product/fetchProductByParams",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payloadBrand),
      }
    );

    const brandResData = await brandRes.json();
    setProductData(brandResData.data);
    console.log("brandResData", brandResData);
  };

  useEffect(() => {
    recommendedProducts(categoryId, brandId, subCatId);
  }, [categoryId, brandId, subCatId]);

  const tryon = (e, id) => {
    // data.setProduct(id);
    cleverTapEvent(undefined, "VTO_Similar_Products_Clicked");

    let user = localStorage.getItem("userId");
    let url = user
      ? `/?product=${id}&userId=${user}`
      : `/?product=${id}&ymk=true`;
    // `/?product=${id}`;
    navigate(url);
    // HandleClose();
    window.location.reload();
  };

  const [WishlistIds, setWishlistIds] = useState([]);

  useEffect(() => {
    const ids = wishlistDataSet.map((data) => data.product_id);
    setWishlistIds(ids);
  }, [wishlistDataSet]);

  // const WishlistHandler = (id) => {
  //   console.log("WishlistFilter", WishlistIds.includes(id), WishlistIds ,id);
  //   if (WishlistIds.includes(id)) {
  //     const filter = WishlistIds.filter((filter) => filter === id);
  //     // console.warn("WishlistFilter", filter);
  //     console.warn("====>WishlistIds", WishlistIds);
  //     // setWishlistIds((prev) => [...prev, filter]);
  //   }
  // };

  // console.log("productData", productData);
  // ------------------------------------------------------------------------------------------------
  const scrollableRef = useRef(null);

  const handleScroll = () => {
    const container = scrollableRef.current;

    // Check if the user has scrolled to the bottom of the container
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight
    ) {
      // Reset state when scrolled to the bottom
      setShowSimilarProducts(true);
    }

    // Check if the user is scrolling up
    if (container.scrollTop === 0) {
      setShowSimilarProducts(false);
    }
  };

  useEffect(() => {
    const container = scrollableRef.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <>
      {productData && productData?.length != 0 && (
        <div className="recommendedWrapper" {...handlers}>
          <div
            className="HeadScroll"
            // onClick={() => setShowSimilarProducts(true)}
            {...handlers}
          >
            <div className="black_btn"></div>
          </div>
          <div
            className="productTitle"
            style={{ paddingBottom: "7px" }}
            {...handlers}
          >
            <h2
              className="font-Harmonia px-3 py-2"
              style={{ fontSize: "1.15rem  " }}
            >
              Similar Products
            </h2>
          </div>
          <div
            ref={scrollableRef}
            style={{
              height: "400px",
              overflowY: "scroll",
              paddingBottom: "5rem",
            }}
            className={`productsWrapper ${
              showSimilarProducts ? "productsWrapperScroll" : ""
            }`}
          >
            {productData &&
              productData.map((item) => {
                // let discount = 0;
                // if (item.list_price > 0) {
                //   discount = parseInt(((item.list_price - item.your_price) / item.list_price) * 100);
                // }
                let discountList = item?.extras[0]?.color_list[0]?.discountList;
                var percentageDiff = 0;
                percentageDiff = parseInt(discountList[0]?.percentage) || 0;
                // console.log("percentageDiff>>",percentageDiff)
                var variantPrices = 0;

                // console.log("item.list_price>>>>",typeof(item.list_price))
                if (percentageDiff) {
                  // console.log("percentageDiff>>>",percentageDiff)
                  var variantPrice2 = (item.list_price * percentageDiff) / 100;
                  variantPrices = (item.list_price - variantPrice2).toFixed();
                  // setVariantDiscount(percentageDiff);
                  // setVariantPrice(variantPrices);
                  // console.log("variantPrice>>>>",variantPrice)
                }

                const isAdded = wishlistDataSet.some(
                  (data) => data.product_id === item.id
                );

                return (
                  item?.extras[0]?.color_list &&
                  item?.extras[0]?.color_list.length > 0 &&
                  item?.stock > 0 &&
                  item?.extras[0]?.color_list.some(
                    (color) => color.color !== "" && color.color !== "Multi" && color.color !== null
                  ) && (
                    <React.Fragment key={item.id}>
                      <div className="cardContainer">
                        <div className="upperContent">
                          <p className="m0 cardTitle font-RedHat">
                            {item?.brand?.name}
                          </p>
                          <div>
                            <img
                              className="productImg"
                              src={item.imageList[0]?.path}
                              width={150}
                              height={150}
                              alt={item.name}
                              title={item.name}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              padding: "2px 10px",
                              borderRadius: "3px",
                              fontSize: "9px",
                              fontWeight: "600",
                              // lineHeight: "12px",
                              color: "#000",
                              // background: item?.extras[0]?.color_list[0]?.color,
                              // background: `${item?.category?.color}`,
                              background: `#ffc7e9`,
                              // background: `#EBEBEB`,
                            }}
                          >
                            {item?.leafCategories[0]?.title}
                          </div>
                        </div>

                        <div className="middleContent">
                          <div className="productDetail">
                            <p className="m0 productName font-Pretendard ">
                              {item.name}
                            </p>
                            <p className="m0 productSize font-RedHat">
                              {item.size}
                            </p>
                          </div>
                          <div className="productPrice">
                            <p className="actualPrice m0 font-RedHat">
                              {variantPrices == 0 ? "" : "₹" + variantPrices}
                            </p>
                            {item.list_price !== 0 && (
                              <p
                                className={
                                  percentageDiff === 0
                                    ? " actualPrice m0 font-RedHat"
                                    : "comparePrice  m0 font-RedHat"
                                }
                              >
                                ₹{item.list_price}
                              </p>
                            )}

                            <p className="discount m0 font-RedHat">
                              {percentageDiff == 0 ? "" : percentageDiff + "%"}
                            </p>
                          </div>
                        </div>
                        <p className="shades m0 font-RedHat">{item.shades}</p>
                        <div className="bottomContent">
                          <button
                            className="favBtn"
                            type="button"
                            onClick={(e) => {
                              handleAddToCart(e, !isAdded, item.id);
                              // WishlistHandler(item.id);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 30 30"
                              fill={
                                WishlistIds.includes(item.id)
                                  ? "#FF55BC"
                                  : "none"
                              }
                            >
                              <path
                                d="M5.56335 17.3853L14.3153 25.6068C14.6398 25.9116 14.802 26.064 15 26.064C15.198 26.064 15.3602 25.9116 15.6847 25.6068L24.4367 17.3853C26.8819 15.0882 27.1788 11.3082 25.1223 8.65758L24.7356 8.15918C22.2753 4.98823 17.337 5.52002 15.6083 9.14206C15.3641 9.6537 14.6359 9.6537 14.3917 9.14206C12.663 5.52002 7.72465 4.98823 5.26443 8.15918L4.87773 8.65759C2.82118 11.3083 3.11813 15.0882 5.56335 17.3853Z"
                                stroke="#FF55BC"
                                strokeWidth="1.5"
                              />
                            </svg>
                          </button>
                          <div className="atcBtns_wrraper">
                            <button
                              className="atcAction"
                              onClick={(e) => {
                                // tryon(e, item?.id);
                                fetchData(item?.id);
                                
                              }}
                            >
                              <span className="ATC_button_action font-Harmonia">
                                <span>TRY IT ON</span>
                              </span>
                              <div className="productLoader hidden"></div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )
                );
              })}
            
          </div>
          {/* <div style={{ paddingBottom: "5rem" }}></div> */}
        </div>
      )}
    </>
  );
};

export default Productrecommendations;
